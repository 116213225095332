import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { TreeDTO } from 'src/app/feature-modules/base-information/Dto/UnitDto';

import { ShareDataService } from 'src/app/shared/service/share-data-service';


@Component({
  selector: 'unit-tree-view',
  templateUrl: './unit-tree-view.component.html',
  styles: [`
    .treeview {
      list-style-type: none;
      }
      .parent{
        color: green;
      }
    .treeview  {
      font-weight: bold;
      cursor: pointer;
    }
    .treeview span {
      display: inline-block;
    }
    .treeview .node-opened {
      transform: rotate(90deg);
    }
`]
})
export class UnitTreeViewComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.data.currentValue != undefined) {

      const cloned = this.data.map(x => Object.assign([], x));
      this.nodes = this.prepareData ? this._getPreparedData(cloned) : this.data;
    }
  }

  constructor(private shareDataService: ShareDataService) { }

  private _collapseAll: boolean;
  private _selectAll: boolean;

  public nodes: any[] = [];
  public collapseAttr: string = 'isCollapsed';
  public selectAttr: string = 'isSelected';
  public inDeterminateAttr: string = 'isIndeterminate';

  @Input('IsChackBox') IsChackBox: boolean = false;

  @Input('data') data: Array<TreeDTO>;
  //data: Array<TreeDTO>;
  /**
   * A flag indicating data is flatten in array and prepare is required.(Default
   * is true).
   */
  @Input('prepareData') prepareData: boolean = true;

  /**
   * Name of ID property in input data.
   */
  @Input('idAttr') idAttr: string = 'UnitId';

  /**
   * Name of parent property in input data.
   */
  @Input('parentAttr') parentAttr: string = 'ParentId';


  /**
   * Name of children list property in input data.
   */
  @Input('childrenAttr') childrenAttr: string = 'CHILDREN';

  SelectId: any;
  /**
   * Collapse or expand all parent nodes.
   */
  @Input('collapseAll')
  set collapseAll(value: boolean) {
    this._collapseAll = value;
    this._recursiveEdit(
      this.nodes, this.childrenAttr, this.collapseAttr, value);
  }

  /**
   * Select or deselect all nodes.
   */
  @Input('selectAll')
  set selectAll(value: boolean) {
    this._selectAll = value;
    this._recursiveEdit(this.nodes, this.childrenAttr, this.selectAttr, value);
    this._recursiveEdit(this.nodes, this.childrenAttr, this.inDeterminateAttr, false);
  }

  /**
   * When change a node model this event will be emit.
   */
  // @Output("onChange") onChange = new EventEmitter()

  @Output("Select") Select = new EventEmitter<any>()

  /**
   * On click node.
   */
  // @Output("onClick") onClick = new EventEmitter();


  ngOnInit() {


  }


  onModelChange(node) {
     
    // if (node[this.childrenAttr].length) {
    if (node[this.selectAttr] == false) {
      if (this.shareDataService.CheckBoxDataNode.some(x => x.UnitId == node.UnitId))
        this.shareDataService.CheckBoxDataNode.splice(this.shareDataService.CheckBoxDataNode.findIndex(x => x.UnitId == node.UnitId));
    }
    else
      this.shareDataService.CheckBoxDataNode.push({ UnitId: node.UnitId, expanded: node.expanded, ParentId: node.ParentId, UnitName: node.UnitName, UnitCode: node.UnitCode })

    // this._recursiveEdit(
    //   [node], this.childrenAttr, this.selectAttr, node[this.selectAttr]);
    // }

  }

  click(node: any) {

    if (node[this.childrenAttr].length) {
      node[this.collapseAttr] = !node[this.collapseAttr]
    }
    // this.Select.emit(node);
  }

  OnSelect(selectedNode: TreeDTO) {

    this.shareDataService.DateNode = selectedNode;

    this.SelectId = selectedNode.UnitId;
  }
  onCheckSelect(UnitId: string) {
    return this.SelectId == UnitId;
  }

  change(value: any) {
    const parent = this.nodes.filter(
      (item) => { return item.ID === value[this.parentAttr] })[0];
    if (parent) {
      let hasDifferent = false, duplicate = {},
        isIndeterminate = value[this.inDeterminateAttr] || false;

      parent[this.childrenAttr].forEach((item) => {
        duplicate[item[this.selectAttr]] =
          (duplicate[item[this.selectAttr]] || 0) + 1;
        if (item[this.inDeterminateAttr]) {
          isIndeterminate = true;
        }
      });
      if (Object.keys(duplicate).length === 1 && !isIndeterminate) {
        parent[this.inDeterminateAttr] = false;
        parent[this.selectAttr] = JSON.parse(Object.keys(duplicate)[0]);
        //  this.onChange.emit(parent);
      } else {
        parent[this.inDeterminateAttr] = true;
        // this.onChange.emit(parent);
      }
    }
  }

  private _recursiveEdit(list, childrenAttr, attr, value) {

    if (Array.isArray(list)) {
      for (let i = 0, len = list.length; i < len; i++) {
        list[i][attr] = value;
        if (list[i][childrenAttr].length) {
          this._recursiveEdit(list[i][childrenAttr], childrenAttr, attr, value);
        }
      }
    }
  }

  private _getPreparedData(list) {

    let tree = [], lookup = {};
    for (let i = 0, len = list.length; i < len; i++) {
      lookup[list[i][this.idAttr]] = list[i];
      list[i][this.childrenAttr] = [];
      list[i][this.collapseAttr] = true;
      list[i][this.selectAttr] = false;
      list[i][this.inDeterminateAttr] = false;
    }
    for (let i = 0, len = list.length; i < len; i++) {
      if (list[i][this.parentAttr]) {
        lookup[list[i][this.parentAttr]][this.childrenAttr].push(list[i]);
      } else {
        tree.push(list[i]);
      }
    }
    return tree;
  };
}
