<input type="file" #fileInput style="display: none" id="imageUpload" accept=".png, .jpg, .jpeg" (change)="fileChangeEvent($event)" />
<!-- <mat-card class="example-card"> -->
    <!-- <a mat-mini-fab color="primary" *ngIf="imageState=='NewFile'" (click)="fileInput.click()">
        <mat-icon>add_a_photo</mat-icon>
    </a> -->

    
    <!-- <div style="width: 100%;max-height: 90%;" style="text-align: center;border: 1px solid #ddd;"> -->
        <img mat-card-image title="Click to change image..." [src]="image" style="cursor: pointer;border: 1px solid #ddd;margin-bottom: 20px;" [ngStyle]="{'width': imageWidth + 'px'}" (click)="fileInput.click()" ngClass="imageClass">
        <div *ngIf="showBtns">
            <button type="button" *ngIf="imageState=='NewFile'" (click)="fileInput.click()" class="btn btn-outline-primary btn-block mg-b-10" style="width: 50%;"><i class="fa fa-upload mg-r-10"></i> Select Image</button>
            <button type="button" *ngIf="imageState=='IsDeleteFile'" (click)="onDelete()" class="btn btn-outline-danger btn-block mg-b-10" style="width: 50%;"><i class="fa fa-trash mg-r-10"></i> Remove Image</button>
            <button type="button" *ngIf="imageState=='ReadyToUpload'" (click)="onUpload()" class="btn btn-outline-success btn-block mg-b-10" style="width: 50%;"><i class="fa fa-upload mg-r-10"></i> Ready to upload</button>
        </div>
        <!-- <button *ngIf="imageState=='NewFile'" (click)="fileInput.click()" class="btn btn-outline-primary btn-block mg-b-10" style="width: 50%;"><i class="fa fa-upload mg-r-10"></i> Select image</button> -->
        <!-- <a mat-mini-fab color="primary" *ngIf="imageState=='ReadyToUpload'" (click)="onUpload()">
            <mat-icon>cloud_upload</mat-icon>
        </a> -->
    <!-- </div> -->
     
    
<!-- </mat-card> -->
<!-- <mat-progress-bar mode="determinate" *ngIf="progress>0" [value]="progress"></mat-progress-bar> {{progress}}% -->
<!-- <mat-card-header> -->