import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { GridColumn, GridRequest, GridResult } from 'src/app/base-class/grid-classes';
import { PublicService } from 'src/app/core/services/publicService';
import { PublicShareService } from 'src/app/shared/service/public-share-service';

@Component({
  selector: 'app-translation-dlg',
  templateUrl: './translation-dlg.component.html',
  styleUrls: ['./translation-dlg.component.scss']
})
export class TranslationDlgComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<TranslationDlgComponent>,
    private _http: PublicShareService,
    private _publicService: PublicService,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }


  itemsPerPage = this._publicService.itemsPerPage;
  currentPage: number;
  numberOfPages: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  translationSubscription: Subscription;
  searchText: string;
  E_LanguageId: string;
  query: GridRequest = { PageSize: 10, Page: 1, Sort: [], Filters: null }
  queryResult = new GridResult<any>(0, []);

  ngOnInit(): void {



    if (this.data.E_LanguageId != undefined)
      this.E_LanguageId = this.data.E_LanguageId;

    if (this.data.searchText != undefined) {
      this.searchText = this.data.searchText;
      this.onGridFilter(this.searchText)
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onPopulateGrid();
    })
  }

  onPopulateGrid() {

    this.translationSubscription = this._http.getGridPage("PhrasesPanel/List", this.query, 'type=PhrasesText&languageId=' + this.data.E_LanguageId).subscribe((res: GridResult<any>) => {
      this.queryResult = res;
    })

    // this.translationSubscription = this._http.getGridPage("TranslationPanel/GetPhrasesTranslation", this.query, 'LanguageId=' + this.E_LanguageId).subscribe((res: GridResult<any>) => {
    //   this.queryResult = res;
    // })
  }

  onPageChange(event: any) {
    this.itemsPerPage = this.query.PageSize = this.paginator.pageSize;
    this.currentPage = this.paginator.pageIndex;
    this.query.Page = this.paginator.pageIndex + 1;
    this.onPopulateGrid();
  }


  onCancel() {
    this._dialogRef.close(null);
  }

  onselect(item: any) {
    this._dialogRef.close(item.PhrasesId + "##" + item.Title);
  }

  onGridSort(columnName: string) {

    this.query = this._publicService.onGridSort(this.query, columnName);
    this.onPopulateGrid();

  }
  onGridFilter(searchText: any) {
    let columns: GridColumn[] = [
      new GridColumn("TranslationText", "TranslationText", true, 'string', 150, 'contains', true),
    ]
    let res = this._publicService.onGridFilter(this.query, columns, 'Title', searchText, "contains");
    this.query = res.gridRequest;
    if (res.isRefreshGird)
      this.onPopulateGrid();
  }
  ngOnDestroy(): void {

    if (this.translationSubscription)
      this.translationSubscription.unsubscribe();
  }
}
