<style>
    .e-icons{
        color: #333;
        font-size: 26px;
    }
    .e-search:before{
        content:'\e993';
    }
    .e-upload:before{
        content: '\e725';
    }
    .e-font:before{
        content: '\e34c';
    }
    .e-lock:before{
        content: '\eb3a';
    }
    .e-Selection:before{
        content: '\e935';
    }
    .e-edit:before{
        content: '\e7a3';
    }
</style>
<div class="sample-container">
    <ejs-filemanager #filemanagerObj id='overview' [uploadSettings]='fileUploadSettings' [ajaxSettings]='ajaxSettings' [view]='view'  [allowDragAndDrop]='false' 
    [detailsViewSettings]='detailsViewSettings' (popupOpen)='popupOpen($event)' (beforeSend)='beforeSend($event)' (beforePopupClose)='beforePopupClose($event)' 
    (beforeDownload)='beforeDownload($event)' (success)='success($event)' [contextMenuSettings]='contextMenuSettings' [navigationPaneSettings]='navigationPaneSettings'
    (menuOpen)='menuOpen($event)' (menuClick)='menuClick($event)' [toolbarSettings]='toolbarSettings' (toolbarClick)='toolbarClick($event)' height="calc(100vh - 200px)">
     </ejs-filemanager>
</div>