<header-dialog (close)="onCancel()" [title]="'Edit Profile'"></header-dialog>
<form #form="ngForm" (submit)="submitForm(form)" novalidate>
    <div class="wd-100p ht-70 bg-gray-100 bd-t bd-b">
        <div class="row">
            <div class="col-md-6">

            </div>
            <div class="col-md-6">
                <button class="btn btn-light tx-uppercase tx-spacing-6 btn-settings" (click)="onCancel()"
                    type="button">{{ 'Cancel' | translate}}</button>

                <button class="btn btn-primary tx-uppercase tx-spacing-6 btn-settings" [disabled]="form.invalid"
                    type="submit">{{ 'Save'
                    | translate}}</button>

            </div>
        </div>
    </div>
    <mat-dialog-content>
        <app-error [errorList]="errors"></app-error>
        <div class="row mg-t-10">
            <div class="col-md-6">
                <div class="form-layout form-layout-4">
                    <h6 class="tx-gray-800 tx-uppercase tx-bold tx-14 mg-b-10">{{ 'User Details' | translate}}</h6>
                    <p class="mg-b-30 tx-gray-600">{{ 'Fill in the details for the user.' | translate}}</p>
                    <mninput requiredIf="true" [isRequired]="true" [widthControl]="300" name="PersonalName"
                        [id]="'PersonalName'" [placeholder]="'FirstName'" [max_Length]="50"
                        [(ngModel)]="entity.PersonalName"></mninput>

                    <mninput requiredIf="true" [isRequired]="true" [widthControl]="300" name="PersonalLastName"
                        [id]="'PersonalLastName'" [placeholder]="'LastName'" [max_Length]="50"
                        [(ngModel)]="entity.PersonalLastName"></mninput>

                    <mn-combo-box [id]="'eLanguageId'" [multiple]="false" name="eLanguageId"
                        [placeholder]="'Select Application Language'" [(ngModel)]="entity.E_LanguageId"
                        [apiUrl]="'EnumPanel/getByEnumType?EnumType=Language'" [valueField]="'EnumId'"
                        [labelField]="'EnumName'"></mn-combo-box>

                    <mn-combo-box [id]="'eThemId'" [multiple]="false" name="eThemId"
                        [placeholder]="'Select Application Theme'" [(ngModel)]="entity.E_ThemId"
                        [apiUrl]="'EnumPanel/getByEnumType?EnumType=Theme'" [valueField]="'EnumId'"
                        [labelField]="'EnumName'"></mn-combo-box>

                    <!-- <mn-combo-box [id]="'CategoryIds'" [multiple]="true" name="CategoryIds"
                        [placeholder]="'Select Categories'" [(ngModel)]="entity.CategoryIds" [valueField]="'Id'"
                        [Items]="categoryList" [labelField]="'Name'"></mn-combo-box> -->

                </div>
                <div class="form-layout form-layout-4"
                    style="padding-top: 5px !important;padding-bottom: 5px !important;">
                    <h6 class="tx-gray-800 tx-uppercase tx-bold tx-14 mg-b-10">{{ 'Password' | translate}}</h6>
                    <mat-checkbox [(ngModel)]="isChengePassword" [ngModelOptions]="{standalone: true}"
                        [labelPosition]="'after'" (change)="entity.Password=''; entity.RePassword=''">{{ 'Change Your
                        Password' | translate}}</mat-checkbox>

                    <div>
                        <mninput requiredIf="true" [isRequired]="true" *ngIf="isChengePassword" [type]="'password'"
                            [widthControl]="300" name="'password'" [id]="'Password'" [placeholder]="'Enter Password'"
                            [max_Length]="300" [(ngModel)]="entity.Password"></mninput>
                    </div>
                    <div>
                        <mninput requiredIf="true" [isRequired]="true" *ngIf="isChengePassword" [type]="'password'"
                            [widthControl]="300" name="repassword" [id]="'RePassword'"
                            [placeholder]="'Re-Enter Password'" [max_Length]="300" [(ngModel)]="entity.RePassword">
                        </mninput>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-layout form-layout-4">
                    <h6 class="tx-gray-800 tx-uppercase tx-bold tx-14 mg-b-10">{{ 'Image' | translate}}</h6>
                    <p class="mg-b-30 tx-gray-600">{{ 'Select a photo.' | translate}}</p>
                    <mn-image-upload [(imageInput)]='entity.FileId' [imageWidth]="200"></mn-image-upload>
                </div>
            </div>
        </div>

        <div>

        </div>











    </mat-dialog-content>
    <!-- <mat-dialog-actions>
        <button mat-raised-button color="primary" *ngxPermissionsOnly="['UserUpdate','UserInsert']"
            [disabled]="form.invalid" type="submit">Save</button>
        <a mat-raised-button (click)="onCancel()">Cancel</a>
    </mat-dialog-actions> -->

</form>