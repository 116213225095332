import { Injectable } from '@angular/core';
import { TreeDTO } from 'src/app/feature-modules/base-information/Dto/UnitDto';
import { RecercveFormCommentDto } from '../Dto/form-omment-dto';
import { AoTreeDTO } from '../ui-components-shared/classes/base-classes';

 
@Injectable()
export class ShareDataService {

  constructor( ) {
    
   }

  DateNode:TreeDTO|AoTreeDTO;
  CheckBoxDataNode:any[]=[];
  DataComment:RecercveFormCommentDto;
}
