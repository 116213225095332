<form #form="ngForm" (submit)="submitForm(form)" novalidate>
    <mat-card class="example-card">
        <mat-card-header style="width: 100%;">

            <mat-card-title> {{   "BaseData" | translate}} </mat-card-title>

        </mat-card-header>

        <mat-card-content>
            <app-error [errorList]="errors"></app-error>

            <select-item [feildTitle]="'Select Your Funktion Group'" [title]="entity.FunktionGroupName"
                (OnClick)="OnSelect()"> </select-item>
        </mat-card-content>
        <mat-card-actions>
            <div style="text-align: center;">
                <button mat-raised-button [disabled]="form.invalid" type="submit"
                    color="primary">{{ "Save" | translate}}</button></div>

        </mat-card-actions>

    </mat-card>
</form>