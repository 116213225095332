import { RouterModule, Routes } from '@angular/router';

import { AccessDeniedComponent } from './public-component/access-denied/access-denied.component';
import { AdminLayoutComponent } from './public-component/admin-layout/admin-layout.component';
import { LayoutLoginComponent } from './public-component/layout-login/layout-login.component';
import { LoginComponent } from './public-component/login/login.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './public-component/page-not-found/page-not-found.component';
import { SelectedUserRoleComponent } from './public-component/selected-user-role-component/selected-user-role-component.component';
import { ForgotPasswordComponent } from './public-component/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: "", component: LayoutLoginComponent, children: [
      { path: "", component: LoginComponent },
      { path: "login", component: LoginComponent },
      { path: "forgotPassword", component: ForgotPasswordComponent },
      { path: 'uaa', loadChildren: () => import('./feature-modules/security/security.module').then(m => m.SecurityModule) },
      // { path: 'systems', component:SelectSystemsComponent },
      { path: 'selectrole', component: SelectedUserRoleComponent },
    ]
  },

  {
    path: "panel", component: AdminLayoutComponent,
    children: [
      { path: 'report', loadChildren: () => import('./feature-modules/report/report.module').then(m => m.ReportModule) },
      { path: 'forms', loadChildren: () => import('./feature-modules/form-system/form-system.module').then(m => m.FormSystemModule) },
      { path: 'eo-system', loadChildren: () => import('./feature-modules/eo-system/eo-system.module').then(m => m.EoSystemModule) },
      { path: 'cartable', loadChildren: () => import('./feature-modules/cartable/cartable.module').then(m => m.CartableModule) },
      { path: 'dashboard', loadChildren: () => import('./feature-modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'security', loadChildren: () => import('./feature-modules/security/security.module').then(m => m.SecurityModule) },
      { path: 'baseInfo', loadChildren: () => import('./feature-modules/base-information/base-information.module').then(m => m.BaseInformationModule) },
      { path: 'calender-schedule', loadChildren: () => import('./feature-modules/calender-schedule/calender-schedule.module').then(m => m.CalenderScheduleModule) },
    ]
  },
  { path: '403', component: AccessDeniedComponent },
  { path: '**', component: PageNotFoundComponent }

];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
