import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, Input } from '@angular/core';
import {
  FileManagerComponent, NavigationPaneService, ToolbarService, DetailsViewService,
  MenuOpenEventArgs, MenuClickEventArgs, ToolbarClickEventArgs, ContextMenu
} from '@syncfusion/ej2-angular-filemanager';
import { MatDialog } from '@angular/material/dialog';
import { PublicService } from 'src/app/core/services/publicService';
import { PublicShareService } from 'src/app/shared/service/public-share-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { IAppConfig, APP_CONFIG } from 'src/app/core/services/IAppConfig';
import { userInfo } from 'src/app/core/services/userInfo';
import { EditFileComponent } from 'src/app/feature-modules/form-system/components/edit-file/edit-file.component';
import { DocEditorComponent } from 'src/app/feature-modules/form-system/components/doc-editor/doc-editor.component';
import { DocMailmergeComponent } from 'src/app/feature-modules/form-system/components/doc-mailmerge/doc-mailmerge.component';
import { BrowserStorageService } from 'src/app/core/services/browser-storage.service';

@Component({
  selector: 'fm',
  templateUrl: './fm.component.html',
  styleUrls: ['./fm.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NavigationPaneService, ToolbarService, DetailsViewService]
})
export class FmComponent {
  saveSubscription: Subscription;
  public contextMenu: ContextMenu;
  public navigationPaneSettings: object;
  public isFolder: boolean;
  public ajaxSettings: object;
  public view: string;
  // public hostUrl: string = 'http://81.233.129.44:4037/';
  // public hostUrlNew: string = 'http://81.233.129.44:4037/api/FileManagerPanel/SQLFileOperationsDTO';
  public hostUrl: string = this.appConfig.apiEndpoint + '/';
  public hostUrlNew: string = this.appConfig.apiEndpointFM;
  public detailsViewSettings: object;
  public fileUploadSettings: object;
  public toolbarSettings: object;
  @ViewChild('filemanagerObj')
  public filemanagerObj: FileManagerComponent;
  public contextMenuSettings: object;
  public currentUserId: string;
  public currentAction: string;
  public fileId: string = "5DBAFA4F-F365-49DC-8B99-0C4EF93FB0DD";

  @Input('rootId') entityId: string = "00000000-0000-0000-0000-000000000000";
  @Input('rootTitle') entityTitle: string = "";

  constructor(@Inject(APP_CONFIG) private appConfig: IAppConfig,
    private browserStorageService: BrowserStorageService,
    private _http: PublicShareService,
    private _publicService: PublicService,
    private dialog: MatDialog) {
    this.currentUserId = userInfo.id;
  }

  fileSubscription: Subscription;
  public ngOnInit(): void {

    this.currentUserId = sessionStorage.getItem("currentUserId");
    this.fileUploadSettings = {
      autoUpload: false
    }
    this.navigationPaneSettings = { minWidth: '100px', maxWidth: '200px', visible: true }
    this.contextMenuSettings = { file: ['Edit...', '|', 'Open', 'New File', 'Check out', 'Undo Check out', 'Download', '|', 'Copy', 'Cut', 'SelectAll', '|', 'Delete', '|', 'Rename', 'Details', 'MailMerge'], folder: ['Open', 'Edit Folder', '|', 'Copy', 'Cut', 'Paste', 'SelectAll', '|', 'Delete', '|', 'Rename', 'Details'], layout: ['NewFolder', 'New File', '|', 'Paste', '|', 'SelectAll', 'View', 'Refresh'], visible: true };
    this.ajaxSettings = {
      // url : this.hostUrl + "api/SQLProvider/SQLFileOperations",
      url: this.hostUrlNew,
      // downloadUrl : "http://localhost:62869/api/SQLProvider/SQLDownload",
      // uploadUrl : this.hostUrl + "api/SQLProvider/SQLUpload",
      // getImageUrl : this.hostUrl + "api/SQLProvider/SQLGetImage"
      // downloadUrl : this.hostUrl + "api/FileManagerPanel/SQLDownload",
      // uploadUrl : this.hostUrl + "api/FileManagerPanel/SQLUpload",
      // uploadUrl:"http://localhost:2517/api/FileUploadPanel?formType=CarProfile",
      // getImageUrl : this.hostUrl + "api/FileManagerPanel/SQLGetImage"

      // downloadUrl : "https://ser.arinaone.com/tmp",
      // uploadUrl:"https://ser.arinaone.com/api/FileManagerPanel?formType=CarProfile",
      // getImageUrl : 'https://ser.arinaone.com/api/FileManagerPanel/SQLFileOperationsDTO'

      downloadUrl: this.appConfig.apiEndpointFMdownloadUrl,
      uploadUrl: this.appConfig.apiEndpointFMuploadUrl,
      getImageUrl: this.appConfig.apiEndpointFMgetImageUrl
    };
    this.toolbarSettings = { items: ['NewFolder', 'Refresh', 'View', 'Details'], visible: false };
    this.view = "Details";
    // Details View settings customization
    this.detailsViewSettings = {
      columns: [
        { field: 'name', headerText: 'Name', minWidth: 120, width: '150', customAttributes: { class: 'e-fe-grid-name' }, template: '${name}' },
        { field: 'type', headerText: 'Type', minWidth: 50, width: '100' },
        { field: 'convertedSize', headerText: 'Size', minWidth: 50, width: '110', template: '${convertedSize}' },
        { field: 'docStatus', headerText: 'Status', minWidth: 50, width: '110' },
        { field: 'description', headerText: 'Description', minWidth: 50, width: '150' },
        { field: 'version', headerText: 'Version', minWidth: 50, width: '100' },
        { field: 'checkedOutByName', headerText: 'Checked Out', minWidth: 50, width: '200' },
        { field: '_fm_modified', headerText: 'Date Modified', minWidth: 50, width: '190' }
      ]
    };
  }

  menuOpen(args: MenuOpenEventArgs) {

    args.items[this.filemanagerObj.getMenuItemIndex('Undo Check out')].id = 'UndoCheckout';
    if (this.filemanagerObj.itemData[0]["checkedOutByName"] === "") {
      this.filemanagerObj.disableMenuItems(['UndoCheckout']);
    }

    if (this.filemanagerObj.itemData[0]["type"] == "AODC" || this.filemanagerObj.itemData[0]["type"] == "AOXLS") {
      this.filemanagerObj.disableMenuItems(['Download']);
    }

    for (var i = 0; i < args.items.length; i++) {
      if (args.items[i].text === 'Check out') {
        args.items[i].id = 'CheckOut';
        if (this.filemanagerObj.itemData[0]["checkedOutByName"] === "")
          args.items[i].iconCss = 'e-icons e-lock';
        else {
          args.items[i].text = 'Check in';
          args.items[i].iconCss = 'e-icons e-Selection';
          if (args.fileDetails[0]["type"] == "AODC")
            this.filemanagerObj.disableMenuItems(['CheckOut']);
        }
      }
      else if (args.items[i].text === 'Edit...' || args.items[i].text === 'Edit Folder') {
        args.items[i].id = 'Edit';
        args.items[i].iconCss = 'e-icons e-edit';
      }
    }
    if (this.filemanagerObj.itemData.length > 0) {
      if (this.filemanagerObj.itemData[0]["checkedOutByName"] !== "" && this.filemanagerObj.itemData[0]["checkedOutBy"] != this.currentUserId) {
        this.filemanagerObj.disableMenuItems(['Cut', 'CheckOut', 'Edit', 'Delete', 'Rename']);
      }
    }
  }
  menuClick(args: MenuClickEventArgs) {
    if (this.filemanagerObj.itemData.length > 0) {
      this.filemanagerObj.itemData[0]["EntityId"] = this.entityId;
      this.filemanagerObj.itemData[0]["EntityTitle"] = this.entityTitle;
    }
    else {
      this.filemanagerObj.itemData["EntityId"] = this.entityId;
      this.filemanagerObj.itemData["EntityTitle"] = this.entityTitle;
    }

    if (args.item.text === 'Edit Folder') {
      this.isFolder = true;
      this.onEdit(this.filemanagerObj.itemData[0]["id"], false);
    }
    // else if (args.item.text === 'New Document') {
    //   this.isFolder=false;
    //   this.onNewDoc();
    // }
    else if (args.item.text === 'New File') {
      this.isFolder = false;
      this.onNew();
    }
    else if (args.item.text === 'Edit...') {
      this.isFolder = false;
      this.onEdit(this.filemanagerObj.itemData[0]["id"], false);
    }
    else if (args.item.text === 'MailMerge') {
      this.isFolder = false;
      this.onMailMerge(this.filemanagerObj.itemData[0]["id"], true, true);
    }
    else if (args.item.text === 'Open') {
      args.cancel = true;
      if (args.fileDetails[0]["isFile"]) {
        if (args.fileDetails[0]["type"] == "gantt")
          this.openGantt(this.filemanagerObj.itemData[0]["id"], false);
        else if (args.fileDetails[0]["type"] == "AODC" || args.fileDetails[0]["type"] == "AOXLS" || args.fileDetails[0]["type"] == "AOTMP") {
          if (this.filemanagerObj.itemData[0]["checkedOutBy"] == this.currentUserId)
            this.onEditor(this.filemanagerObj.itemData[0]["id"], true, true, args.fileDetails[0]["type"]);
          else
            this.onEditor(this.filemanagerObj.itemData[0]["id"], false, false, args.fileDetails[0]["type"]);
        }
        else {

          this.saveSubscription = this._http.downloadFiles("FilemanagerPanel/dl", JSON.stringify(this.filemanagerObj.itemData)).subscribe(
            (res: any) => {
              window.open(
                this.hostUrl + 'tmp/_tmp/' + res,
                '_blank'
              );

            });

          // this.filemanagerObj.downloadFiles(this.filemanagerObj.itemData[0]["id"]);
          // window.location.href= this.hostUrl + 'tmp/' + this.filemanagerObj.itemData[0]["id"] + this.filemanagerObj.itemData[0]["type"];

          // window.open(
          //   this.hostUrl + 'tmp/' + this.filemanagerObj.itemData[0]["id"] + "_v" + 
          //   this.filemanagerObj.itemData[0]["version"].replace(".","_") + this.filemanagerObj.itemData[0]["type"],
          //   '_blank' 
          // );
        }
      }
    }
    else if (args.item.text === 'Check out' || args.item.text === 'Check in') {
      // this.filemanagerObj.downloadFiles(this.filemanagerObj.itemData[0].id);
      let sAction = "checkOut";
      if (args.item.text === 'Check in') {
        this.currentAction = "CheckIn";
        sAction = "checkIn";
        if (args.fileDetails[0]["type"] == "gantt" || args.fileDetails[0]["type"] == "AODC" || args.fileDetails[0]["type"] == "AOTMP") {
          let data = { Action: sAction, itemDetails: null, data: this.filemanagerObj.itemData, itemPermissions: null };
          this.saveSubscription = this._http.SaveFolder("", JSON.stringify(data)).subscribe(
            (res: any) => {
              if (!res.error) {
                this._publicService.onSuccessMessege(args.item.text + " successfull!");
                this.filemanagerObj.refreshFiles();
              }
              else
                this._publicService.onErorMessege(res.error["message"]);
            });
        }
        else
          this.onEdit(this.filemanagerObj.itemData[0]["id"], true);
      }
      else {
        args.cancel = true;
        this.filemanagerObj.itemData[0]["checkedOutBy"] = this.currentUserId;
        let data = { Action: sAction, itemDetails: null, data: this.filemanagerObj.itemData, itemPermissions: null };
        this.saveSubscription = this._http.SaveFolder("", JSON.stringify(data)).subscribe(
          (res: any) => {
            if (!res.error) {
              if (sAction === "checkOut" && this.filemanagerObj.itemData[0]["type"].indexOf(".") >= 0) {
                this.saveSubscription = this._http.downloadFiles("FilemanagerPanel/dl", JSON.stringify(this.filemanagerObj.itemData)).subscribe(
                  (res: any) => {
                    window.open(
                      this.hostUrl + 'tmp/_tmp/' + res,
                      '_blank'
                    );

                  });
              }
              // // this.filemanagerObj.downloadFiles(this.filemanagerObj.itemData[0]["id"]);
              // window.open(
              //   this.hostUrl + 'tmp/' + this.filemanagerObj.itemData[0]["id"] + "_v" + 
              //   this.filemanagerObj.itemData[0]["version"].replace(".","_")  + this.filemanagerObj.itemData[0]["type"],
              //   '_blank' 
              // );
              // // window.location.href= this.hostUrl + 'tmp/' + this.filemanagerObj.itemData[0]["id"] + this.filemanagerObj.itemData[0]["type"];
              else if (args.fileDetails[0]["type"] == "gantt")
                this.openGantt(this.filemanagerObj.itemData[0]["id"], true);
              else if (args.fileDetails[0]["type"] == "AODC" || args.fileDetails[0]["type"] == "AOXLS" || args.fileDetails[0]["type"] == "AOTMP")
                this.onEditor(this.filemanagerObj.itemData[0]["id"], true, false, args.fileDetails[0]["type"]);

              this._publicService.onSuccessMessege(args.item.text + " successfull!");
              this.filemanagerObj.refreshFiles();
            }
            else
              this._publicService.onErorMessege(res.error["message"]);
          });
      }
    }
    else if (args.item.text === 'Undo Check out') {
      let data = { Action: "undocheckout", itemDetails: null, data: this.filemanagerObj.itemData, itemPermissions: null };
      this.saveSubscription = this._http.SaveFolder("", JSON.stringify(data)).subscribe(
        (res: any) => {
          if (!res.error) {
            this._publicService.onSuccessMessege(args.item.text + " successfull!");
            this.filemanagerObj.refreshFiles();
          }
          else
            this._publicService.onErorMessege(res.error["message"]);
        });
    }
  }
  toolbarClick(args: ToolbarClickEventArgs) {
    args.cancel = true;
    if (args.item.text === 'Download') {
      if (args.fileDetails[0]["isFile"]) {
        window.open(
          this.hostUrl + 'tmp/' + this.filemanagerObj.itemData[0]["id"] + this.filemanagerObj.itemData[0]["type"],
          '_blank'
        );
      }
    }
  }
  popupOpen(args: any): void {
    // alert(args.popupName);
    // alert(args.popupModule.content);
    // args.popupModule.content="<input type='text' value='Description' id='description'>" +args.popupModule.content;
    // this.filemanagerObj.uploadFiles();
    // let obj = this.filemanagerObj.getSelectedFiles();

    // args.element.childNodes[2].childNodes[0].click();
    args.cancel = true;


  }
  beforePopupOpen(args: any): void {
    // alert(args.popupName);
    // alert(args.popupModule.content);
    // args.popupModule.content="<input type='text' value='Description' id='description'>" +args.popupModule.content;
    // this.filemanagerObj.uploadFiles();
    // let obj = this.filemanagerObj.getSelectedFiles();

    // args.element.childNodes[2].childNodes[0].click();
    args.cancel = true;


  }
  beforePopupClose(args: any): void {
    // alert(args.popupModule.content);
  }
  success(args: any): void {
    //   this.ajaxSettings = {
    //     url : this.hostUrl + "api/SQLProvider/SQLFileOperations",
    //     downloadUrl : this.hostUrl + "api/SQLProvider/SQLDownload",
    //     uploadUrl : this.hostUrl + "api/SQLProvider/SQLUpload",
    //     getImageUrl : this.hostUrl + "api/SQLProvider/SQLGetImage"
    // };
  }
  beforeSend(args: any): void {
    debugger;
    let token = this.browserStorageService.getLocal("access_token");
    args.ajaxSettings.beforeSend = function (args) {
      debugger;
      args.httpRequest.setRequestHeader('Authorization', 'Bearer ' + token);
    }
    let thisData = JSON.parse(args.ajaxSettings.data);
    thisData.CurrentUserId = this.currentUserId;
    thisData.EntityId = this.entityId;
    thisData.EntityTitle = this.entityTitle;

    if (thisData[2]) {
      let subData = JSON.parse(thisData[2]["data"]);
      subData.CurrentUserId = this.currentUserId;
      subData.EntityId = this.entityId;
      subData.EntityTitle = this.entityTitle;
      thisData[2]["data"] = JSON.stringify(subData);
    }

    args.ajaxSettings.data = JSON.stringify(thisData);

  }
  beforeDownload(args: any): void {
    args.cancel = true;
    if (this.currentAction == "checkOut") {
      args.data.action = "checkOut";
      args.data.data[0].checkedOutBy = this.currentUserId;
    }
    this.saveSubscription = this._http.downloadFiles("FilemanagerPanel/dl", JSON.stringify(this.filemanagerObj.itemData)).subscribe(
      (res: any) => {
        // return new Blob([res.body], { type: res.fileExtension });
        window.open(
          this.hostUrl + 'tmp/_tmp/' + res,
          '_blank'
        );
      });




  }
  // toolbarClick(args: ToolbarClickEventArgs) {
  //   if (args.item.text === 'Add Folder') {
  //     this.isFolder=true;
  //       this.onNew();
  //   }
  //   else if (args.item.text === 'Edit Folder') {
  //     this.isFolder=true;
  //     this.onEdit(this.filemanagerObj.itemData[0].id);
  //   }
  //   else if (args.item.text === 'Add File') {
  //     this.isFolder=false;
  //       this.onNew();
  //   }
  //   else if (args.item.text === 'Edit File') {
  //     this.isFolder=false;
  //     // this.onEdit(this.filemanagerObj.itemData[0].id);
  //   }
  // }
  onMailMerge(id: string, bEdit: boolean, bDraft: boolean) {
    const dialogRef = this.dialog.open(DocMailmergeComponent, {
      disableClose: true,
      width: '1800px',
      height: '2000px',
      autoFocus: true,
      data: {
        fmObject: this.filemanagerObj, cUserId: this.currentUserId, FileId: id, bEditable: bEdit,
        fileVersion: this.filemanagerObj.itemData[0]["version"], bDraft: bDraft
      },
    });

    dialogRef.afterClosed().subscribe(
      res => {
        if (res)
          this.ngOnInit();
        setTimeout(() => {
          this.filemanagerObj.refreshFiles();
        }, 500);

      });

  }
  onEditor(id: string, bEdit: boolean, bDraft: boolean, fType: string) {
    const dialogRef = this.dialog.open(DocEditorComponent, {
      disableClose: true,
      width: '1800px',
      height: '2000px',
      autoFocus: true,
      data: {
        fmObject: this.filemanagerObj, cUserId: this.currentUserId, FileId: id, bEditable: bEdit,
        fileVersion: this.filemanagerObj.itemData[0]["version"], bDraft: bDraft, fileType: fType
      },
    });

    dialogRef.afterClosed().subscribe(
      res => {
        if (res)
          this.ngOnInit();
        setTimeout(() => {
          this.filemanagerObj.refreshFiles();
        }, 500);

      });

  }
  onNew() {
    const dialogRef = this.dialog.open(EditFileComponent, {
      disableClose: true,
      height: '600px',
      width: '1000px',
      autoFocus: true,
      data: { fmObject: this.filemanagerObj, isFolder: this.isFolder, cUserId: this.currentUserId, EntityId: this.entityId, EntityTitle: this.entityTitle },

    });

    dialogRef.afterClosed().subscribe(
      res => {
        if (res)
          this.ngOnInit();
      });

  }
  onEdit(id: string, bCheckIn: boolean) {
    let sType = this.filemanagerObj.itemData[0]["type"];

    const dialogRef = this.dialog.open(EditFileComponent, {
      disableClose: true,
      height: '600px',
      width: '1000px',
      autoFocus: true,
      data: {
        ItemId: id, fmObject: this.filemanagerObj, isFolder: this.isFolder, isCheckIn: bCheckIn,
        sFileType: sType, cUserId: this.currentUserId, EntityId: this.entityId, EntityTitle: this.entityTitle
      },

    });

    dialogRef.afterClosed().subscribe(
      res => {
        if (res)
          this.ngOnInit();
      });

    // this.Dialog.show();

  }
  openGantt(id: string, bEdit: boolean) {
    // if(this.filemanagerObj.itemData[0]["checkedOutBy"] == this.currentUserId)
    //   bEdit=true;
    // else
    //   bEdit=false;

    // const dialogRef = this.dialog.open(PganttComponent, {
    //     disableClose: true,
    //     // width:'1600px',
    //     autoFocus: true,
    //     data: { ProjectId: undefined, FileId:id, bEditable:bEdit },

    //   });

    //   dialogRef.afterClosed().subscribe(
    //     res => {
    //       if (res)
    //         this.ngOnInit();
    //     });

    // // this.Dialog.show();

  }

}
