import { InjectionToken } from "@angular/core";

export let APP_CONFIG = new InjectionToken<string>("app.config");

export interface IAppConfig {
  apiEndpoint: string;
  apiSettingsPath: string;
  imageSrc: string;
  //fakeapiEndpoint:string;
  fileSrc: string;
  apiEndpointFM: string;
  apiEndpointFMOne: string;
  apiEndpointFMDeleted: string;
  apiEndpointFMGen: string;
  apiEndpointFMdownloadUrl: string;
  apiEndpointFMuploadUrl: string;
  apiEndpointFMgetImageUrl: string;
}
//PassAbbasAbadi2019
export const AppConfig: IAppConfig = {


  // apiEndpoint: "http://localhost:2517",
  // apiSettingsPath: "/api/",
  // imageSrc: "http://localhost:2517/ImageUpload/",
  // fileSrc: "http://localhost:2517/FileUpload/",
  // apiEndpointFM: "http://localhost:2517/api/FileManagerPanel/SQLFileOperationsDTO/",
  // apiEndpointFMOne: "http://localhost:2517/api/FileManagerPanel/getOne",
  // apiEndpointFMDeleted: "http://localhost:2517/api/FileManagerPanel/getDeleted",
  // apiEndpointFMGen: "http://localhost:2517/api/FileManagerPanel",
  // apiEndpointFMdownloadUrl: "http://localhost:2517/tmp",
  // apiEndpointFMuploadUrl: "http://localhost:2517/api/FileManagerPanel?ft=null",
  // apiEndpointFMgetImageUrl: 'http://localhost:2517/api/FileManagerPanel/SQLFileOperationsDTO'

  apiEndpoint: "https://ser.arinaone.com",
  apiSettingsPath: "/api/",
  imageSrc: "https://ser.arinaone.com/ImageUpload/",
  fileSrc: "https://ser.arinaone.com/FileUpload/",
  apiEndpointFM: "https://ser.arinaone.com/api/FileManagerPanel/SQLFileOperationsDTO/",
  apiEndpointFMOne: "https://ser.arinaone.com/api/FileManagerPanel/getOne",
  apiEndpointFMDeleted: "https://ser.arinaone.com/api/FileManagerPanel/getDeleted",
  apiEndpointFMGen: "https://ser.arinaone.com/api/FileManagerPanel",
  apiEndpointFMdownloadUrl: "https://ser.arinaone.com/tmp",
  apiEndpointFMuploadUrl: "https://ser.arinaone.com/api/FileManagerPanel?ft=null",
  apiEndpointFMgetImageUrl: 'https://ser.arinaone.com/api/FileManagerPanel/SQLFileOperationsDTO'

};
