<!-- ########## START: LEFT PANEL ########## -->
<div class="br-logo" style="border-left: 15px solid #0070ac;"><img src="assets/images/Icon_AO.png" width="40" class="mr-2" /><a href=""><span>[</span>Arina
    <i>One</i><span>]</span></a></div>


<div class="br-sideleft overflow-y-auto" style="overflow-x: hidden; border-left: 15px solid #0070ac;">
  <label class="sidebar-label pd-x-10 mg-t-20 op-3">Navigation</label>
  <ul class="br-sideleft-menu">
    <!-- <li class="dropdown">
          <a href="javascript:void(0);" class="br-menu-link" mat-menu-item [matMenuTriggerFor]="animals">
              <i class="menu-item-icon icon ion-ios-home-outline tx-28"></i>
              <span class="menu-item-label">{{ 'Search' | translate}}</span>
          </a>
          <mat-menu #animals="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="vertebrates">Vertebrates</button>
            <button mat-menu-item [matMenuTriggerFor]="invertebrates">Invertebrates</button>
          </mat-menu>
          
          <mat-menu #vertebrates="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="fish">Fishes</button>
            <button mat-menu-item [matMenuTriggerFor]="amphibians">Amphibians</button>
            <button mat-menu-item [matMenuTriggerFor]="reptiles">Reptiles</button>
            <button mat-menu-item>Birds</button>
            <button mat-menu-item>Mammals</button>
          </mat-menu> -->
    <!-- <div class="dropdown-menu pd-10 wd-200">
            <nav class="nav nav-style-2 flex-column">
              <a class="nav-link" href="javascript:void(0);" *ngxPermissionsOnly="['ToolView']"><i class="icon ion-document"></i> {{ 'Files' | translate}}</a>
              <a class="nav-link" href="javascript:void(0);" (click)="searchBycat('project')" *ngxPermissionsOnly="['ToolView']"><i class="icon ion-ios-briefcase-outline"></i> {{ 'Projects' | translate}}</a>
              <a class="nav-link" href="javascript:void(0);" (click)="searchBycat('order')" *ngxPermissionsOnly="['ToolView']"><i class="icon ion-ios-cart-outline"></i> {{ 'Orders' | translate}}</a>
              <a [routerLink]="['/panel/baseInfo/tool', {srchcat:'ToolNumber', srchString:srchString}]"  class="nav-link" href="javascript:void(0);" *ngxPermissionsOnly="['ToolView']"><i class="icon ion-wrench"></i> {{ 'Special Tools' | translate}}</a>
              <a class="nav-link" href="javascript:void(0);" (click)="searchBycat('img')" *ngxPermissionsOnly="['ToolView']"><i class="icon ion-images"></i> {{ 'Illustrations' | translate}}</a>
              <a class="nav-link" href="javascript:void(0);" (click)="searchBycat('profile')" *ngxPermissionsOnly="['ToolView']"><i class="icon ion-ios-pricetags-outline"></i> {{ 'Car Profiles' | translate}}</a>
              <a class="nav-link" href="javascript:void(0);" (click)="searchBycat('phrase')" *ngxPermissionsOnly="['ToolView']"><i class="icon ion-code-working"></i> {{ 'Phrases' | translate}}</a>
            </nav>
          </div>
      </li> -->
    <li class="dropdown">
      <a [routerLink]="['/panel/dashboard']" class="br-menu-link">
        <i class="menu-item-icon icon ion-ios-home-outline tx-28"></i>
        <span class="menu-item-label">{{ 'Dashboard' | translate}}</span>
      </a>
      <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <mat-icon>dialpad</mat-icon>
                <span>Redial</span>
              </button>
              <button mat-menu-item disabled>
                <mat-icon>voicemail</mat-icon>
                <span>Check voice mail</span>
              </button>
              <button mat-menu-item>
                <mat-icon>notifications_off</mat-icon>
                <span>Disable alerts</span>
              </button>
            </mat-menu> -->
    </li>
    <!-- <li class="br-menu-item">
          <a [routerLink]="['/panel/forms/filemgr']" class="br-menu-link">
              <i class="menu-item-icon icon ion-ios-copy-outline tx-28"></i>
              <span class="menu-item-label">{{'File Management' | translate}}</span>
          </a>
      </li> -->
    <!-- <li class="br-menu-item">
        <a [routerLink]="['/panel/forms/recbin']" class="br-menu-link">
            <i class="menu-item-icon icon ion-ios-trash-outline tx-28"></i>
            <span class="menu-item-label">{{'Recycle Bin' | translate}}</span>
        </a>
    </li> -->
    <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
  </ul>
</div>
<!-- ########## END: LEFT PANEL ########## -->

<!-- ########## START: HEAD PANEL ########## -->
<div class="br-header">
  <div class="br-header-left">
    <div class="navicon-left hidden-md-up"><a id="btnLeftMenu" [routerLink]="['/panel/dashboard']"><img
          src="assets/images/Icon_AO.png" width="40" /></a></div>
    <!-- <div class="navicon-left hidden-md-down"><a id="btnLeftMenu" ><i class="icon ion-navicon-round"></i></a></div> -->
    <div class="navicon-left hidden-lg-up"><a id="btnLeftMenuMobile"><i class="icon ion-navicon-round"></i></a></div>
    
    <div class="input-group hidden-xs-down wd-170 transition" style="display: none;">
      <input id="searchbox" type="text" class="form-control" placeholder="Search">
      <span class="input-group-btn">
        <button class="btn btn-secondary" type="button"><i class="fa fa-search"></i></button>
      </span>
    </div>
    <!-- <div class="row">
      <div class="col-md-1" style="padding-right: 0 !important;">
        <input id="searchbox" type="text" class="form-control" [(ngModel)]="srchString" placeholder="{{ 'Search by category' | translate}}...">

      </div>
      <div class="col-md-10">
        <div class="input-group bd rounded-30 hidden-xs-down">
          <input id="searchbox" type="text" class="form-control mg-l-20" style="padding: 0 !important;"
            [(ngModel)]="srchString" placeholder="{{ 'Search for' | translate}}...">
          <span class="input-group-btn">
            <div class="dropdown bd-0">
              <a href="" class="tx-gray-800 d-inline-block" data-toggle="dropdown">
                <div class="ht-30 w-30 pd-x-20 d-flex align-items-center justify-content-center">
                  <i class="fa fa-search mg-r-10"></i>
                  <i class="fa fa-angle-down"></i>
                </div>
              </a>
              <div class="dropdown-menu pd-10 wd-200">
                <nav class="nav nav-style-2 flex-column">
                  <a class="nav-link" href="javascript:void(0);" *ngxPermissionsOnly="['FileView']"><i class="icon ion-document"></i> {{ 'Files' | translate}}</a>
                  <a class="nav-link" href="javascript:void(0);"
                    [routerLink]="['/panel/baseInfo/Project', {srchcat:'ProjectTitle', srchString:srchString}]"
                    *ngxPermissionsOnly="['ProjectView']"><i class="icon ion-ios-briefcase-outline"></i> {{ 'Projects' |
                    translate}}</a>
                  <a class="nav-link" href="javascript:void(0);"
                    [routerLink]="['/panel/baseInfo/order', {srchcat:'Title', srchString:srchString}]"
                    *ngxPermissionsOnly="['OrderView']"><i class="icon ion-ios-cart-outline"></i> {{ 'Orders' |
                    translate}}</a>
                  <a class="nav-link" href="javascript:void(0);"
                    [routerLink]="['/panel/baseInfo/tool', {srchcat:'ToolNumber', srchString:srchString}]"
                    *ngxPermissionsOnly="['ToolView']"><i class="icon ion-wrench"></i> {{ 'Parts' | translate}}</a>
                </nav>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div> -->

  </div><!-- br-header-left -->
  <div class="br-header-right">
    <mn-combo-box #langSelect [id]="'lang'" [multiple]="false" name="lang"
        (ngModelChange)="onChangeLang(langSelect.value)" [placeholder]="''" [(ngModel)]="lang"
        [apiUrl]="'EnumPanel/getWithOutIdEnumTypeCode?EnumType=Language'" [valueField]="'Id'" [labelField]="'Name'">
      </mn-combo-box>
    <nav class="nav">
      <div class="dropdown">
        <a href="" class="nav-link nav-link-profile" data-toggle="dropdown">
          <span class="logged-name hidden-md-down">{{entity.FullName }}</span>
          <img [src]="serverUrl+entity.ImagePath" class="wd-32 rounded-circle" alt="">
          <span class="square-10 bg-success"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-header wd-250">
          <div class="tx-center">
            <a href=""><img [src]="serverUrl+entity.ImagePath" class="wd-80 rounded-circle" alt=""></a>
            <h6 class="logged-fullname">{{entity.FullName }}</h6>
            <p>{{entity.UnitName}}</p>
          </div>
          <hr>
          <ul class="list-unstyled user-profile-nav">
            <li><a (click)="onShowProfile()"><i class="icon ion-ios-locked-outline"></i> {{ 'My Profile' |
                translate}}</a></li>
            <li><a [routerLink]="['/selectrole']"><i class="icon ion-key"></i> {{ 'Select Role' | translate}}</a></li>
            <li><a (click)="onlogOut()"><i class="icon ion-power"></i> {{ 'Sign Out' | translate}}</a></li>
          </ul>
        </div><!-- dropdown-menu -->
      </div><!-- dropdown -->
    </nav>
  </div>
</div><!-- br-header -->
<!-- ########## END: HEAD PANEL ########## -->

<!-- ########## START: MAIN PANEL ########## -->
<div class="br-mainpanel">
  <div class="br-pagebody">
    <router-outlet #routerOutlet="outlet"></router-outlet>
    <footer class="br-footer">
      <div class="footer-center">
        <div class="mg-b-2">{{ 'Copyright &copy; 2003-2024. Arinasoft AB. All Rights Reserved.' | translate}}</div>
      </div>
    </footer>
  </div><!-- br-pagebody -->

</div><!-- br-mainpanel -->
<!-- ########## END: MAIN PANEL ########## -->