<li class="br-menu-item" (click)="SelectNode(item)" [ngClass]="{'width-sub show-sub':isSubMenu}">
    <a (click)="onItemSelected(item,$event)" class="br-menu-link" [class.active]="router.isActive(item.Url, true)"
        [class.with-sub]="item.children ? item.children.length>0 :false" [class.show-sub]="expanded">
        <i [ngClass]="showIcon ? 'menu-item-icon icon ' + item.IconName + ' tx-24':''"></i>
        <span class="menu-item-label">{{item.Title | translate}} </span>
    </a>
    <ul *ngIf="expanded" class="br-menu-sub" [ngStyle]="{'display':isSubMenu?'block':'none'}">
        <li *ngFor="let child of item.children" class="sub-item">
            <a (click)="onItemSelected(child,$event)" class="sub-link"
                [class.active]="router.isActive(item.Url, true)">{{child.Title | translate}}</a>
        </li>
    </ul>
</li>






<!-- <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
</app-menu-list-item>  -->

<!-- <a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
    [ngClass]="{'active': item.Url ? router.isActive(item.Url, true): false, 'expanded': expanded}"
    class="menu-list-item">
    <mat-icon class="routeIcon">{{item.IconName}}</mat-icon>
    {{ item.Title | translate }}

    <span fxFlex *ngIf="item.children && item.children.length">
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
            expand_more
        </mat-icon>
    </span>
</a>
<div *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
    </app-menu-list-item>
</div> -->