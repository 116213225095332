<div *ngIf="!IsMultiple else multipleSec">

    <a *ngIf="FileId else notfound" [href]="pathServerUrl+'&id='+FileId+'&formType='+formType">
        <mat-icon> cloud_download</mat-icon>
        <!-- <br/> {{lable}} -->
        {{lable}}
    </a>
    <ng-template #notfound></ng-template>

</div>
<ng-template #multipleSec>
    <button type="button" mat-raised-button color color="primary" (click)="onShowFiles()">
        <!-- <mat-icon>cloud_upload</mat-icon> -->
      </button>

</ng-template>