import { Component, OnInit, AfterViewInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { TreeDTO } from 'src/app/feature-modules/base-information/Dto/UnitDto';
import { MatDialog } from '@angular/material/dialog';
import { UnitTreeDemoViewComponent } from '../unit-tree-demo-view/unit-tree-demo-view.component';
import { CreateInformationDataDto } from '../../Dto/information-data-dto';
import { Subscription } from 'rxjs';
import { PublicService } from 'src/app/core/services/publicService';
import { PublicShareService } from '../../service/public-share-service';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'funktion-group-item',
  templateUrl: './funktion-group-item.component.html',
  styleUrls: ['./funktion-group-item.component.scss']
})
export class FunktionGroupItemComponent implements OnInit, OnInit, OnDestroy, OnChanges, AfterViewInit {

  constructor(
    private dialog: MatDialog,
    private _http: PublicShareService,
    private _publicService: PublicService
  ) { }

  @Input("ExternalId") ExternalId: string
  @Input("formType") FormType: string;
  @Input("Ishackbox") isChackBox: boolean = false;


  errors: string[] = [];

  entity: CreateInformationDataDto = { InformationDataId: undefined, FormType: '', E_ModelIds: [], E_YMIds: [], E_EngineIds: [], E_GearBoxIds: [], E_FunktionGroupIds: [], ExternalId: '', FunktionGroupName: '' };

  getRecordSubscription: Subscription;
  saveSubscription: Subscription;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // setTimeout(() => { 

    //   this.getOne();
    // })
  }


  OnSelect() {

    const dialogRef = this.dialog.open(UnitTreeDemoViewComponent,
      {
        disableClose: true,
        autoFocus: true,
        data: {
          apiUrl: "EnumPanel/GetTreeByEnumTypeCode?enumType=FunktionGroup",
          IsChackBox: this.isChackBox
        }
      });

    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.entity.E_FunktionGroupIds = res.map(c => c.UnitId);
          this.entity.FunktionGroupName = res.map(c => c.UnitName).join(',')
        }
      });
  }



  ngOnChanges(changes: SimpleChanges): void {

    if (changes.ExternalId.currentValue != undefined && changes.ExternalId.currentValue != "" && changes.ExternalId.currentValue != null) {
      this.entity.ExternalId = changes.ExternalId.currentValue;
      this.entity.FormType = changes.FormType.currentValue;
      this.getOne();
    }

  }
  getOne() {

    this.getRecordSubscription = this._http.getAll("InformationDataPanel?externalId=" + this.ExternalId + "&formType=" + this.FormType).subscribe((res) => {
      this.entity = res;
    });

  }

  submitForm(form: NgForm) {
    let isEdit = false;
    if (!form.valid)
      return;
     
    this.entity.ExternalId = this.ExternalId;
    this.entity.FormType = this.FormType;

    let url = "InformationDataPanel/Create";
    if (this.entity.InformationDataId !== null) {
      url = "InformationDataPanel/Edit";
      isEdit = true

    }
    this.errors = [];
    this.saveSubscription = this._http.Save(url, this.entity, isEdit).subscribe(
      (res: any) => {
        this._publicService.onSuccessMessege(res.Msg);
        this.entity.InformationDataId = res.InformationDataId;
      },
      (responseError: HttpErrorResponse) => {
        this.errors = this._publicService.processModelStateErrors(form, responseError);
      });
  }
  onCancel() {

  }
  ngOnDestroy(): void {
    if (this.saveSubscription != null)
      this.saveSubscription.unsubscribe();


    if (this.getRecordSubscription! != null)
      this.getRecordSubscription.unsubscribe();
  }




}
