<form #form="ngForm" (submit)="submitForm(form)" novalidate>
    <mat-card class="example-card">
        <!-- <mat-card-header style="width: 100%;">

            <mat-card-title> {{   "BaseData" | translate}} </mat-card-title>

        </mat-card-header> -->

        <mat-card-content>
            <app-error [errorList]="errors"></app-error>
            <div *ngIf="showBaseInformation else showFunktionGroup">
                <mn-combo-box [id]="'eModels'" [multiple]="true" name="eModels" [placeholder]="'Model'"
                    [(ngModel)]="entity.E_ModelIds" [apiUrl]="'EnumPanel/getByEnumType?EnumType=Model'"
                    [valueField]="'EnumId'" [labelField]="'EnumName'">
                </mn-combo-box>

                <mn-combo-box [id]="'eYearModels'" [multiple]="true" name="eYearModels"
                    [placeholder]="'Year Model'" [(ngModel)]="entity.E_YMIds"
                    [apiUrl]="'EnumPanel/getByEnumType?EnumType=YearModel'" [valueField]="'EnumId'"
                    [labelField]="'EnumName'"></mn-combo-box>

                <mn-combo-box [id]="'eEngineIds'" [multiple]="true" name="Engine" [placeholder]="'Engine'"
                    [(ngModel)]="entity.E_EngineIds" [apiUrl]="'EnumPanel/getByEnumType?EnumType=Engine'"
                    [valueField]="'EnumId'" [labelField]="'EnumName'"></mn-combo-box>

                <mn-combo-box [id]="'eGearBoxIds'" [multiple]="true" name="eGearBoxIds"
                    [placeholder]="'GearBox'" [(ngModel)]="entity.E_GearBoxIds"
                    [apiUrl]="'EnumPanel/getByEnumType?EnumType=GearBox'" [valueField]="'EnumId'"
                    [labelField]="'EnumName'">
                </mn-combo-box>
            </div>

            <ng-template #showFunktionGroup>
                <select-item [feildTitle]="'Funktion Group'" [title]="entity.FunktionGroupName"
                    (OnClick)="OnSelect()"> </select-item>
            </ng-template>
            
        </mat-card-content>
        <mat-card-actions>
            <div style="text-align: center;">
                <button mat-raised-button [disabled]="form.invalid" type="submit"
                    color="primary">{{ "Save" | translate}}</button></div>

        </mat-card-actions>

    </mat-card>
</form>