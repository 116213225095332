<div *ngIf="ExternalId" >
    <div class="row">
        <!-- <div class="col-md-4"> -->
            <!-- <a mat-raised-button style="margin: 10px;" (click)="OnNewComment(newComment)" color="primary">{{ "InsertComment" | translate}}</a> -->
            <!-- <div #newComment> -->
                <!-- <form #form="ngForm" (submit)="submitForm(form)" novalidate> -->
                    <!-- <mat-card class="example-card">
                        <mat-card-header style="width: 100%;">
            
                            <mat-card-title> {{   "UserComment" | translate}} </mat-card-title>
            
                        </mat-card-header>
            
                        <mat-card-content>
            
                            

                            <app-error [errorList]="errors"></app-error>
                            <div>
                                <mninput [widthControl]="300" name="title" [id]="'Title'"
                                    [placeholder]="'EnterYourTitle'" [max_Length]="350" [(ngModel)]="entity.Title"></mninput>
                            </div>
                            <mntext-area requiredIf="true" [isRequired]="true" [widthControl]="300" name="text" [id]="'Text'"
                                [placeholder]="'EnterYourDescription'" [max_Length]="4000" [rows]="3" [(ngModel)]="entity.Text">
                            </mntext-area>
            
                        </mat-card-content>
                        <mat-card-actions>
                            <div style="text-align: right;"><button mat-raised-button (click)="addComment()" type="button"
                                    color="primary">{{ "SaveComment" | translate}}</button></div>
            
                        </mat-card-actions>
            
                    </mat-card> -->
                <!-- </form> -->
            <!-- </div> -->
        <!-- </div> -->
        <div class="col-md-12">
            <div #newComment class="input-group" style="width: 90%;">
                <input type="text" class="form-control" placeholder="Comment here..." [(ngModel)]="entity.Text">
                <div class="input-group-append">
                    <button class="btn btn-primary" type="button" [disabled]="entity.Text==''" (click)="addComment()" style="padding-top: 0px !important;padding-bottom: 0px !important;"><mat-icon class="example-tab-icon">send</mat-icon></button>
                </div>
            </div>
            <div style="overflow-y: auto; height: 500px;">
                <comment-view *ngFor="let item of commentlst" (inputComment)="OnInsertAddToComment($event,newComment)" [item]="item">
                </comment-view>
            </div>
        </div>
    </div>

    
    <!-- <div #myerr style="width: 500px;height: 200px;background-color: red;"> </div> -->
</div>