<header-dialog (close)="onCancel()" [title]="'Select'">
</header-dialog>
<div class="wd-100p ht-70 bg-gray-100 bd-t bd-b">
<div class="row">
    <div class="col-md-6">
      
    </div>
    <div class="col-md-6">
      <button class="btn btn-light tx-uppercase tx-spacing-6 btn-settings" (click)="onCancel()" type="button">Cancel</button>
      <button class="btn btn-primary tx-uppercase tx-spacing-6 btn-settings" (click)="onClick()">{{"Select" | translate}}</button>
    </div>
  </div>
</div>
<div class="form-layout form-layout-4">
    <h6 class="tx-gray-800 tx-uppercase tx-bold tx-14 mg-b-10">Select Item</h6>
<div style="height: 250px; overflow:auto; width: 600px; border: 1px solid #ddd;">
        <!-- [collapseAll]="collapseAll" [selectAll]="selectAll" -->
    <unit-tree-view [data]="treeData" [IsChackBox]="IsChackBox" >
    </unit-tree-view>

    <!-- <a mat-raised-button color="primary" (click)="onClick()">{{"Select" | translate}} </a> -->
</div>
</div>  