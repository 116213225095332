<header-dialog (close)="onCancel()" [title]="'File Managment'"></header-dialog>
<mat-dialog-content>
    <div style="height: 50%;">
        <div *ngFor="let item of resultList  ;let i=index">
            <div style="color:green;"> {{item.FileName}} </div>
            <div>
                <a [href]="pathServerUrl+'&id='+item.FileId+'&formType='+item.FormType">
                    <mat-icon> attach_file</mat-icon>
                </a>
            </div>
        </div>
    </div>
</mat-dialog-content>