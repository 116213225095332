import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditFileComponent } from '../edit-file/edit-file.component';
import { DocumentEditorContainerComponent, ToolbarService } from '@syncfusion/ej2-angular-documenteditor';

@Component({
  selector: 'app-doc-mailmerge',
  templateUrl: './doc-mailmerge.component.html',
  styleUrls: ['./doc-mailmerge.component.scss'],
  providers: [ToolbarService]
})
export class DocMailmergeComponent implements OnInit {

  constructor(private dialog: MatDialog,
    private _dialogRef: MatDialogRef<EditFileComponent>) { }

  ngOnInit(): void {
  }
  onCancel() {
    this._dialogRef.close(false);
  }
}
