 
<!-- <div [ngStyle]="{'padding-left': (depth * 30) + 'px'}"> -->

<div class="row ao-comment tx-roboto tx-10" [ngStyle]="{'margin-left': (depth * 10) + 'px'}">
  <div class="col-md-2">
    <img mat-card-avatar [src]="imageUrl+ item.ImageUser" > 
    
      <!-- <div class="tx-gray-600">{{item.UnitName}}</div> -->
  </div>
  <div class="col-md-10">
    <table style="height: 100%;width: 100%;">
      <tr>
        <td><div class="tx-12 tx-bold">{{item.UserName}}</div></td>
      </tr>
      <tr>
        <td class="tx-12 ht-40">{{item.Text}}</td>
      </tr>
      <tr>
        <td class="tx-gray-600" style="vertical-align: middle;"><mat-icon style="font-size: 14px !important;height: 14px !important;width: 14px !important;">access_time</mat-icon><span>{{item.InsertDate | date:'dd MMM y, hh:mm:ss'}}</span></td>
      </tr>
      <!-- <tr>
        <td>
          <div #newComment class="input-group" style="width: 100%;">
            <input type="text" class="form-control" style="width: 80%;" placeholder="Reply here..." >
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" [disabled]="item.Text==''" (click)="onInsertComment(item)" style="padding-top: 0px !important;padding-bottom: 0px !important;"><mat-icon class="example-tab-icon">send</mat-icon></button>
            </div>
        </div>
        </td>
      </tr> -->
    </table>
  </div>
</div>

  <!-- <mat-card class="example-card"  >
    <mat-card-header style="width: 100%;">
        <img mat-card-avatar [src]="imageUrl+ item.ImageUser"  class="image-Circle-smal">   
          <mat-card-title   >{{item.UserName}}   </mat-card-title>  
          <p  style=" margin-left: 20%; text-align: right;vertical-align: middle;"><mat-icon class="example-tab-icon">access_time</mat-icon><span style="vertical-align: middle;">{{item.InsertDate | date:'dd MMM y, hh:mm:ss'}}</span></p>
        <mat-card-subtitle> {{item.UnitName}}  </mat-card-subtitle>  
    </mat-card-header>
      
    <mat-card-content>
        <div [innerHTML]="item.Text"></div>
    </mat-card-content>
    <mat-card-actions > -->
      <!-- <div class="input-group" style="width: 70%;">
        <input type="text" class="form-control" placeholder="Search for..." [(ngModel)]="item.Text">
        <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="onInsertReply(item.CommentId)"><i class="fa fa-search"></i></button>
        </div>
      </div> -->
      <!-- <div style="text-align: right;"><button mat-raised-button color="primary" (click)="onInsertComment(item)"   >{{ "Reply_to_comment" | translate}}</button></div>
      
    </mat-card-actions>
</mat-card> -->

<!-- </div>  -->

<comment-view *ngFor="let child of item.Children" [item]="child"    (inputComment)="onInsertComment($event)"  [depth]="depth+1">
</comment-view>
