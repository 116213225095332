<div class="row">
    <div class="col-md-12">
        <mat-card style="margin-top: 5px;border: 1px solid #ddd;margin: 20px;">
            <mat-card-header>
                <mat-card-subtitle>
                    <h5>Select System</h5>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="row row-sm">
                    <div class="col-sm-6 col-xl-4 mg-t-20 mg-xl-t-0" *ngFor="let item of queryResult">
                        <div class="bg-br-primary rounded overflow-hidden">
                            <div class="pd-25 d-flex align-items-center">
                                <i class="ion ion-clipboard tx-60 lh-0 tx-white op-7"></i>
                                <div class="mg-l-20">
                                    <p class="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">
                                        {{item.Name}}</p>
                                    <p class="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1"> <button
                                            class="btn btn-primary pd-y-12 btn-block mg-b-30"
                                            [routerLink]="['/selectrole',item.SystemId]">  Select System </button> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
              <div class="bg-primary rounded overflow-hidden">
                <div class="pd-25 d-flex align-items-center">
                  <i class="ion ion-stop tx-60 lh-0 tx-white op-7"></i>
                  <div class="mg-l-20">
                    <p class="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">Not Started</p>
                    <p class="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1">{{myTasksStatus.notStartedTasks}}</p>
                  </div>
                </div>
              </div>
            </div> 
            <div class="col-sm-6 col-xl-3">
              <div class="bg-success rounded overflow-hidden">
                <div class="pd-25 d-flex align-items-center">
                  <i class="ion ion-play tx-60 lh-0 tx-white op-7"></i>
                  <div class="mg-l-20">
                    <p class="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">In progress</p>
                    <p class="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1">{{myTasksStatus.ongoingTasks}}</p>
                  </div>
                </div>
              </div>
            </div> 
            <div class="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
              <div class="bg-danger rounded overflow-hidden">
                <div class="pd-25 d-flex align-items-center">
                  <i class="ion ion-clock tx-60 lh-0 tx-white op-7"></i>
                  <div class="mg-l-20">
                    <p class="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">Overdue</p>
                    <p class="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1">{{myTasksStatus.delyedTasks}}</p>
                  </div>
                </div>
              </div>
            </div>  -->
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>