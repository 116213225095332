<form #form="ngForm" (submit)="submitForm(form)" novalidate>
    <!-- <mat-card class="example-card">
        <mat-card-content>
            <mat-card-header class="mat-card-header-thub-green">
                <mat-card-title> Forgot Password </mat-card-title>
            </mat-card-header> -->
            <app-error [errorList]="errors"></app-error>
            <!-- <mninput requiredIf="true" [isRequired]="true" [widthControl]="420" [type]="'email'" name="email" email [id]="'Email'" [placeholder]="' Enter Email'" [max_Length]="50" [(ngModel)]="entity.Email">
            </mninput> -->
            <div class="form-group mg-l-15 mg-b-20 wd-400">
                <h5 class="tx-invert">Reset password</h5>
                <input #password="ngModel" type="email" name="email" [(ngModel)]="entity.Email" required class="form-control pd-y-12" placeholder="Please enter your e-mail here...">
                <p class="tx-12 mg-t-5">You will receive a temporary password to login to ArinaOne.</p>
                <!-- <mat-error *ngIf="password.invalid">{{ "Password is required" | translate }}</mat-error> -->
                <!-- <a href="javascript:void(0);" (click)="onforgotPassword()" class="tx-12 d-block mg-t-10" >Forgot password?</a> -->
            </div>
        <!-- </mat-card-content>


        <mat-card-actions style="text-align: center; padding: 15px;"> -->
            <button mat-raised-button color="primary" style="width: 120px;" [disabled]="form.invalid" type="submit">Send Email </button>
            <a mat-raised-button style="width: 120px;" [routerLink]="['/login']">Back</a>
        <!-- </mat-card-actions> -->
    <!-- </mat-card> -->
</form>