import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PublicShareService } from '../../service/public-share-service';
import { PublicService } from 'src/app/core/services/publicService';
import { Subscription } from 'rxjs';
import { FileResultDto } from '../../Dto/file-upload-dto';
import { BrowserStorageService } from 'src/app/core/services/browser-storage.service';
import { IAppConfig, APP_CONFIG } from 'src/app/core/services/IAppConfig';

@Component({
  selector: 'app-show-files',
  templateUrl: './show-files.component.html',
  styleUrls: ['./show-files.component.scss']
})
export class ShowFilesComponent implements OnInit, OnDestroy, AfterViewInit {
  MasterId: string;

  constructor(
    private _dialogRef: MatDialogRef<ShowFilesComponent>,

    private publicShareService: PublicShareService,
    private publicService: PublicService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private browserStorageService: BrowserStorageService,
    @Inject(MAT_DIALOG_DATA) private data) { }

  pathServerUrl: string;
  token: string;

  getSubscription: Subscription;
  resultList: FileResultDto[] = [];


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getSubscription = this.publicShareService.getAll("FileUploadPanel/List?MasterId=" + this.MasterId + "&FormType=" + this.data.FormType).subscribe(res => {
        this.resultList = res;

      });
    })
  }



  ngOnInit(): void {
    this.token = this.browserStorageService.getLocal("access_token");
    this.pathServerUrl = this.appConfig.apiEndpoint + this.appConfig.apiSettingsPath + 'DownloadFile?a_t=' + this.token;

    if (this.data.MasterId != undefined) {
      this.MasterId = this.data.MasterId;

    }

  }

  onCancel() {

    this._dialogRef.close(false);
  }

  ngOnDestroy(): void {
    if (this.getSubscription != null)
      this.getSubscription.unsubscribe();
  }


}
