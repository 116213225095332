import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

// import { FileManagerComponent} from '@syncfusion/ej2-angular-filemanager';
import { FieldSettingsModel, ToolbarSettingsModel } from '@syncfusion/ej2-dropdowns';
import { ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';

import { UploadingEventArgs, ActionCompleteEventArgs, UploaderComponent, BeforeUploadEventArgs, SelectedEventArgs } from '@syncfusion/ej2-angular-inputs';
import { MatRadioChange } from '@angular/material/radio';
import { DocEditorComponent } from '../doc-editor/doc-editor.component';
import { Router } from '@angular/router';
import { PublicShareService } from 'src/app/shared/service/public-share-service';
import { PublicService } from 'src/app/core/services/publicService';
import { GridRequest, GridResult } from 'src/app/base-class/grid-classes';
import { UserDto } from 'src/app/feature-modules/security/Dto/UserDto';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/IAppConfig';
import { CreateFileDTO, FileVersionsDTO } from 'src/app/feature-modules/base-information/Dto/FileDto';
import { RelatedFileItemDTO } from './dto/related-file-dto';
import { BrowserStorageService } from 'src/app/core/services/browser-storage.service';





interface tmplate {
  Id: string;
  Name: string;
}

@Component({
  selector: 'app-edit-file',
  templateUrl: './edit-file.component.html',
  styleUrls: ['./edit-file.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditFileComponent implements OnInit {

  ngAfterViewInit(): void {

    setTimeout(() => {
      if (this.isEdit)
        this.getOne();


    })
  }


  constructor(
    private _dialogRef: MatDialogRef<EditFileComponent>,
    private browserStorageService: BrowserStorageService,
    private dialog: MatDialog,
    private _http: PublicShareService,
    private router: Router,
    private _publicService: PublicService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    @Inject(MAT_DIALOG_DATA) private data) { }


  query: GridRequest = { PageSize: 10, Page: 1, Sort: [], Filters: null }
  queryResult = new GridResult<UserDto>(0, []);


  public queryResultSelected: { [key: string]: Object }[];
  public fields: FieldSettingsModel = { text: 'FullName', value: 'UserId' };
  public toolbarSettings: ToolbarSettingsModel = { items: ['moveTo', 'moveFrom', 'moveAllTo', 'moveAllFrom'] }

  @ViewChild('defaultupload')
  public uploadObj: UploaderComponent;
  public autoUpload: boolean = false;
  public path: Object = {
    saveUrl: this.appConfig.apiEndpoint + this.appConfig.apiSettingsPath + 'FileManagerPanel?formType=ci',
    removeUrl: this.appConfig.apiEndpoint + this.appConfig.apiSettingsPath + 'FileManagerPanel/Remove'
  };
  // public path: Object = {
  //   saveUrl: 'https://ser.arinaone.com/api/FileManagerPanel?formType=ci',
  //   removeUrl: 'https://ser.arinaone.com/api/FileManagerPanel/Remove'
  // };
  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;
 

  public hostUrl: string = this.appConfig.apiEndpoint + '/';
  @ViewChild('selectedlist')
  public selectedlist: ListBoxComponent;



  saveSubscription: Subscription;
  usrSubscription: Subscription;
  errors: string[] = [];
  Id: string = undefined;
  isEdit: boolean = false;
  isCheckIn: boolean = false;
  isFolder: boolean = true;
  public isSaving: boolean = false;
  entity: CreateFileDTO = { ItemName: '', ItemDescription: '', E_DocStatusId: '', fType: '', E_LanguageId: '', EntityType: '', TemplateId: 0 };
  fileToUpload: File;
  ParentId: string;
  fileType: string = "upload";
  fileName: string;
  relatedFileItems: RelatedFileItemDTO[] = []
  public fileVersions: FileVersionsDTO[];
  public headerText: Object = [{ text: 'General' }, { text: 'Permissions' }, { text: 'Versions' }, { text: 'Used in' }];

  public tmplates: tmplate[];

  ngOnInit() {

    this._http.getItemsByType("t=AOTMP").subscribe(
      (res: any) => {
        this.tmplates = res;
      });

    if (this.data.sFileType == 'gantt')
      this.fileType = 'gantt';

    this.isCheckIn = this.data.isCheckIn;
    this.isFolder = this.data.isFolder;

    if (this.data.ItemId != undefined) {
      this.Id = this.data.ItemId;
      this.isEdit = true;
      this.onRelatedEo();
    }

    
    this.usrSubscription = this._http.getGridPage("UserPanel/List", this.query, "&oType=UnUs").subscribe((res: GridResult<UserDto>) => {
      this.queryResult = res;
    });

  }

  onRelatedEo() {
    this._http.getAll(`EoPanel/file-related/${this.data.fmObject.itemData[0].id}`).subscribe((res: RelatedFileItemDTO[]) => {
      this.relatedFileItems = res

    })

  }

  onNavigateToEo(item: RelatedFileItemDTO) {
    debugger
    this.router.navigate(["/panel/eo-system/edit-eo", item.CategoryId, item.EoId, "Edit", item.CategoryCode])
    this._dialogRef.close()
  }
  radioChange(args: MatRadioChange) {
    this.fileType = args.value;
  }
  rolbackversion(versionNo: string) {
    if (this.data.fmObject.itemData[0].type == "AODC") {

    }
    else {

    }
  }
  openversion(versionNo: string) {
    // window.open(
    //   this.hostUrl + 'tmp/' + this.data.fmObject.itemData[0].id + "_v" + 
    //   versionNo.replace(".","_") + this.data.fmObject.itemData[0]["type"],
    //   '_blank' 
    // );
    if (this.data.fmObject.itemData[0].type == "AODC" || this.data.fmObject.itemData[0].type == "AOXLS" || this.data.fmObject.itemData[0].type == "AOMRG" || this.data.fmObject.itemData[0].type == "AOTMP") {
      const dialogRef = this.dialog.open(DocEditorComponent, {
        disableClose: true,
        width: '1800px',
        height: '2000px',
        autoFocus: true,
        data: {
          fmObject: this.data.fmObject, cUserId: null, FileId: this.data.fmObject.itemData[0].id,
          bEditable: false, fileVersion: versionNo, bShowVersion: true, bDraft: false, fileType: this.data.fmObject.itemData[0].type
        },
      });

      dialogRef.afterClosed().subscribe(
        res => {
          if (res)
            this.ngOnInit();
        });
    }
    else {
      this.data.fmObject.itemData[0].action = "openversion";
      this.data.fmObject.itemData[0].version = versionNo;
      this.saveSubscription = this._http.downloadFiles("FilemanagerPanel/dl", JSON.stringify(this.data.fmObject.itemData)).subscribe(
        (res: any) => {
          window.open(
            this.hostUrl + 'tmp/_tmp/' + res,
            '_blank'
          );

        });
    }
  }
  getOne() {
    this.saveSubscription = this._http.getItembyId("", this.data.fmObject.itemData[0].id).subscribe(
      (res: any) => {
        this.fileVersions = res.Details.iVersions;
        this.fields = { text: 'FullName', value: 'UserId' };
        this.entity.ItemName = res.Details.Name;
        this.entity.E_LanguageId = res.Details.E_LanguageId;
        this.entity.EntityType = res.Details.EntityType;
        this.entity.ItemDescription = res.Details.Description;
        this.entity.E_DocStatusId = res.Details.E_DocStatusId;
        this.entity.TemplateId = res.Details.TemplateId;
        // this.queryResult.Data=res.Details.iPermissions;
        this.queryResult.Total = res.Details.iPermissions.length;
        this.queryResultSelected = res.Details.iPermissionsSelected;
        // this.defaultupload.allowedExtensions=res.Details.fType;
      },
      (responseError: HttpErrorResponse) => {

      });
  }
  beforeUpload(args: BeforeUploadEventArgs) {


    // let cAction = this.isCheckIn ? "checkIn" : "newFile";
    // let iDetails = {ItemName:this.entity.ItemName,ItemDescription:this.entity.ItemDescription,E_DocStatusId:this.entity.E_DocStatusId}
    // if(this.isCheckIn){
    //   // let iDetails = {ItemName:this.entity.ItemName,ItemDescription:this.entity.ItemDescription}
    //   let data = {Action:cAction,itemDetails: iDetails,data:this.data.fmObject.itemData};
    //   args.customFormData = [{ 'action': cAction }, { 'path': this.data.fmObject.pathNames },{data:JSON.stringify(data)}];
    // }
    // else{
    //   let itemPermissions = [];
    //   for (let index = 0; index < this.selectedlist.getItems().length; index++) {
    //     const thisElement = this.selectedlist.getItems()[index];
    //     itemPermissions.push(thisElement["dataset"].value)
    //   };

    //   let data = {Action:"create",itemDetails: iDetails,data:this.data.fmObject.itemData,itemPermissions:itemPermissions};
    //   args.customFormData = [{ 'action': cAction }, { 'path': this.data.fmObject.pathNames },{data:JSON.stringify(data)}];
    // }
  }

  uploading(args: UploadingEventArgs) {
    args.currentRequest.setRequestHeader('Authorization', 'Bearer ' + this.browserStorageService.getLocal("access_token"));

  }
  selected(args: SelectedEventArgs) {
    if (this.entity.ItemName == '')
      this.entity.ItemName = args.filesData[0].name;

    if (this.entity.ItemDescription == '')
      this.entity.ItemDescription = "Uploaded";
  }
  actionComplete(args: ActionCompleteEventArgs) {
    this.data.fmObject.refreshFiles();
    this._dialogRef.close(false);
    this._publicService.onSuccessMessege("Action successfull!");
  }
  submitForm(form: NgForm) {
    this.isSaving = true;
    let sAction = "create";
    if (this.data.ItemId)
      sAction = "update";

    if ((this.isCheckIn || (!this.Id && !this.isFolder)) && this.fileType != "gantt" && this.fileType != "AODC" && this.fileType != "AOMRG" && this.fileType != "AOTMP" && this.fileType != "AOXLS") {
      let cAction = this.isCheckIn ? "checkIn" : "newFile";
      let iDetails = { ItemName: this.entity.ItemName, E_LanguageId: this.entity.E_LanguageId, EntityType: this.entity.EntityType, ItemDescription: this.entity.ItemDescription, E_DocStatusId: this.entity.E_DocStatusId, TemplateId: this.entity.TemplateId }
      let itemPermissions = [];
      if (!this.isCheckIn && this.selectedlist) {
        for (let index = 0; index < this.selectedlist.getItems().length; index++) {
          const thisElement = this.selectedlist.getItems()[index];
          itemPermissions.push(thisElement["dataset"].value)
        };
      }
      if (this.Id) {
        this.data.fmObject.itemData[0].currentUserId = this.data.cUserId;
      }

      let data = { Action: sAction, itemDetails: iDetails, data: this.data.fmObject.itemData, itemPermissions: itemPermissions, EntityId: this.data.EntityId, EntityTitle: this.data.EntityTitle };
      this.uploadObj.asyncSettings.saveUrl = this.appConfig.apiEndpoint + this.appConfig.apiSettingsPath + "FileManagerPanel?action=" + cAction + "&path=" + this.data.fmObject.pathNames + "&data=" + JSON.stringify(data) + "&E_LanguageId=" + this.entity.E_LanguageId;
      this.uploadObj.upload(this.uploadObj.getFilesData()[0]);
    }
    else if (this.fileType == "gantt" || this.fileType == "AODC" || this.fileType == "AOTMP" || this.fileType == "AOMRG" || this.fileType == "AOXLS") {
      let iDetails = { ItemName: this.entity.ItemName, E_LanguageId: this.entity.E_LanguageId, EntityType: this.entity.EntityType, ItemDescription: this.entity.ItemDescription, E_DocStatusId: this.entity.E_DocStatusId, TemplateId: this.entity.TemplateId }
      let itemPermissions = [];
      if (this.selectedlist) {
        for (let index = 0; index < this.selectedlist.getItems().length; index++) {
          const thisElement = this.selectedlist.getItems()[index];
          itemPermissions.push(thisElement["dataset"].value)
        };
      }
      let data = { path: this.data.fmObject.path, Action: sAction, itemDetails: iDetails, data: this.data.fmObject.itemData, itemPermissions: itemPermissions, type: this.fileType, EntityId: this.data.EntityId, EntityTitle: this.data.EntityTitle };
      this.saveSubscription = this._http.SaveFolder("", JSON.stringify(data)).subscribe(
        (res: any) => {
          if (!res.error) {
            this._publicService.onSuccessMessege("'" + this.entity.ItemName + "' saved successfully!");
            this.data.fmObject.refreshFiles();
          }
          else
            this._publicService.onErorMessege(res.error["message"]);


          this._dialogRef.close(true);
        },
        (responseError: HttpErrorResponse) => {
          this.errors = this._publicService.processModelStateErrors(form, responseError);
        });


    }
    else {
      let itemPermissions = [];
      if (this.selectedlist) {
        for (let index = 0; index < this.selectedlist.getItems().length; index++) {
          const thisElement = this.selectedlist.getItems()[index];
          itemPermissions.push(thisElement["dataset"].value)
        };
      }
      this.errors = [];
      let iDetails = { ItemName: this.entity.ItemName, E_LanguageId: this.entity.E_LanguageId, EntityType: this.entity.EntityType, ItemDescription: this.entity.ItemDescription, E_DocStatusId: this.entity.E_DocStatusId }
      let data = { Action: sAction, itemDetails: iDetails, data: this.data.fmObject.itemData, itemPermissions: itemPermissions, EntityId: this.data.EntityId, EntityTitle: this.data.EntityTitle };

      // if(this.data.ItemId)
      //   data.Action="update";

      this.saveSubscription = this._http.SaveFolder("", JSON.stringify(data)).subscribe(
        (res: any) => {
          if (!res.error) {
            if (this.isEdit)
              this._publicService.onSuccessMessege("'" + this.entity.ItemName + "' saved successfully!");
            this.data.fmObject.refreshFiles();
          }
          else
            this._publicService.onErorMessege(res.error["message"]);


          this._dialogRef.close(true);
        },
        (responseError: HttpErrorResponse) => {
          this.errors = this._publicService.processModelStateErrors(form, responseError);
        });
    }
  }

  onCancel() {
    this._dialogRef.close(false);
  }
  tmplateChanged(value) {
    this.entity.TemplateId = value;
  }

}
