<ul class="treeview" *ngIf="nodes.length">
    <li *ngFor="let node of nodes">

        <span *ngIf="node[childrenAttr].length" [ngClass]="{'node-opened': !node[collapseAttr]}">
          <mat-icon *ngIf="node.expanded" (click)="click(node)" matSuffix>chevron_right</mat-icon>
         </span>
        <!-- <input type="checkbox" [(ngModel)]="node[selectAttr]" [indeterminate]="node[inDeterminateAttr]" (change)="onModelChange(node)" /> -->
   <mat-checkbox  *ngIf="IsChackBox"  [(ngModel)]="node[selectAttr]" [indeterminate]="node[inDeterminateAttr]" (change)="onModelChange(node)"></mat-checkbox>  
        <button    *ngIf="!IsChackBox"   (click)="OnSelect(node)" [ngClass]="{'parent': onCheckSelect(node.UnitId)}" mat-button> {{node.UnitName}}</button>

        <span  *ngIf="IsChackBox">  {{node.UnitName}}</span>
        <unit-tree-view   [IsChackBox]="IsChackBox"   *ngIf="node[childrenAttr].length" [data]="node[childrenAttr]" [prepareData]="false" [ngbCollapse]="node[collapseAttr]">
        </unit-tree-view>

    </li>
        </ul>

<!-- (onChange)="change($event)" -->
