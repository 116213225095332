<header-dialog *ngIf="!isEdit" (close)="onCancel()" [title]="'Add New File'"></header-dialog>
<header-dialog *ngIf="isEdit" (close)="onCancel()" [title]="'Edit Item'"></header-dialog>
<form #form="ngForm" (submit)="submitForm(form)" novalidate>
    <mat-dialog-actions style="background-color: #adadad26;">
        <button mat-raised-button color="primary" [disabled]="form.invalid" type="submit"
            style="float: right;">Save</button>
        <a mat-raised-button (click)="onCancel()" style="float: right;">Cancel</a>
        <mat-progress-bar *ngIf="isSaving" mode="indeterminate" style="margin-top: 10px;"></mat-progress-bar>
    </mat-dialog-actions>
    <mat-dialog-content>
        <app-error [errorList]="errors"></app-error>
        <ejs-tab id="tab_adaptive" #adaptiveTab>
            <e-tabitems>
                <e-tabitem [header]='headerText[0]'>
                    <ng-template #content>
                        <div class="mg-t-30">
                            <div class="row">
                                <div class="col-md-3">
                                    <mat-card *ngIf="!isEdit">
                                        <mat-card-header>
                                            <mat-card-subtitle>Select file type</mat-card-subtitle>

                                        </mat-card-header>
                                        <hr />
                                        <mat-card-content style="overflow: hidden;">
                                            <mat-radio-group *ngIf="!isEdit" aria-label="Select an option"
                                                (change)="radioChange($event)">
                                                <mat-radio-button value="upload" checked>Upload</mat-radio-button>
                                                <mat-radio-button value="AODC">Document</mat-radio-button>
                                                <mat-radio-button value="AOXLS">Spreadsheet</mat-radio-button>
                                                <mat-radio-button value="AOTMP">Template</mat-radio-button>
                                                <mat-radio-button value="AOMRG" [disabled]="true">Auto Content/Publish
                                                </mat-radio-button>
                                                <mat-radio-button value="gantt" [disabled]="true">Gantt
                                                    Chart</mat-radio-button>
                                                <mat-radio-button value="kanban" [disabled]="true">Kanban
                                                    Board</mat-radio-button>
                                            </mat-radio-group>
                                        </mat-card-content>
                                    </mat-card>

                                </div>
                                <div class="col-md-6" style="padding-bottom: 5px;">
                                    <mat-card>
                                        <mat-card-content style="overflow: hidden;">
                                            <ejs-uploader
                                                *ngIf="(isCheckIn===true || (isFolder===false && !Id)) && fileType=='upload' "
                                                [multiple]="false" #defaultupload id='fileupload' [asyncSettings]='path'
                                                [dropArea]='dropElement' [autoUpload]='autoUpload'
                                                (uploading)='uploading($event)'
                                                (actionComplete)='actionComplete($event)' (selected)='selected($event)'>
                                            </ejs-uploader>
                                            <div style="width: 90% !important;"
                                                *ngIf="fileType!='upload' && fileType!='AOTMP' && !isEdit">
                                                <mat-select (selectionChange)="tmplateChanged($event.value)"
                                                    [placeholder]="'Template'">
                                                    <mat-option *ngFor="let tmp of tmplates" [value]="tmp.Id">
                                                        {{tmp.Name}}
                                                    </mat-option>
                                                </mat-select>
                                                <hr />
                                            </div>

                                            <mninput
                                                *ngIf="((isCheckIn==false || (isFolder===false && !Id))) || (isEdit && Id)"
                                                [isRequired]="true" required [widthControl]="380" name="ItemName"
                                                [id]="'ItemName'" [placeholder]="'Name'" [(ngModel)]="entity.ItemName">
                                            </mninput>
                                            <div style="width: 400px !important;">
                                                <mn-combo-box *ngIf="!isFolder" [multiple]="false" name="selStatus"
                                                    [placeholder]="'Status'" [(ngModel)]="entity.E_DocStatusId"
                                                    [apiUrl]="'EnumPanel/getByEnumType?EnumType=ToolStatus'"
                                                    [valueField]="'EnumId'" [labelField]="'EnumName'"></mn-combo-box>
                                            </div>
                                            <div style="width: 400px !important;" *ngIf="fileType=='AOMRG'">
                                                <mn-combo-box [id]="'EntityType'" [multiple]="false" name="EntityType"
                                                    [placeholder]="'Entity Type'" [(ngModel)]="entity.EntityType"
                                                    [apiUrl]="'EnumPanel/getByETypeEx?EnumType=EntityType&exId='"
                                                    [valueField]="'EnumId'" [labelField]="'EnumName'">
                                                </mn-combo-box>

                                                <!-- (ngModelChange)="onChangeEntityType($event)" -->
                                            </div>
                                            <div style="width: 400px !important;">
                                                <mn-combo-box *ngIf="!isFolder" [multiple]="false" name="E_LanguageId"
                                                    [placeholder]="'Language'" [(ngModel)]="entity.E_LanguageId"
                                                    [apiUrl]="'EnumPanel/getByEnumType?EnumType=Language'"
                                                    [valueField]="'EnumId'" [labelField]="'EnumName'"></mn-combo-box>
                                            </div>
                                            <mninput [widthControl]="380" name="ItemDescription"
                                                [id]="'ItemDescription'" [placeholder]="'Description'"
                                                [(ngModel)]="entity.ItemDescription"></mninput>

                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-tabitem>
                <e-tabitem [header]='headerText[1]' *ngIf="!isCheckIn">
                    <ng-template #content>
                        <div class="tabContent">
                            <div *ngIf="isCheckIn==false || (isFolder===false && !Id)" class="dual-list-wrapper">
                                <div class="dual-list-groupa">
                                    <h6>Users</h6>
                                    <ejs-listbox [dataSource]='queryResult.Data' [toolbarSettings]='toolbarSettings'
                                        height='200px' scope='#selectedlist' [fields]='fields'></ejs-listbox>
                                </div>
                                <div class="dual-list-groupb">
                                    <h6>Selected</h6>
                                    <ejs-listbox [dataSource]='queryResultSelected' #selectedlist id='selectedlist'
                                        height='200px' [fields]='fields'></ejs-listbox>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-tabitem>
                <e-tabitem [header]='headerText[2]' *ngIf="!isCheckIn">
                    <ng-template #content>
                        <div class="tabContent">
                            <div class="control-section">
                                <div
                                    style="height: 300px !important; overflow-x: hidden !important; overflow-y: auto !important;">
                                    <table class="fl-table">
                                        <thead>
                                            <tr>
                                                <th>Version</th>
                                                <th>User</th>
                                                <th>Date</th>
                                                <th>Comment</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of fileVersions; let i = index"
                                                style="height: 15px !important;">
                                                <td class="text-center" style="width: 15%;">{{ item.Version }}</td>
                                                <td class="text-center" style="width: 20%;">{{ item.UserName }}</td>
                                                <td class="text-center" style="width: 20%;">{{ item.CDate }}</td>
                                                <td class="text-center" style="width: 35%;">{{ item.Comment }}</td>
                                                <td class="text-center" style="width: 10%;">
                                                    <a title="View..." (click)="openversion(item.Version)">
                                                        <mat-icon class="gridCommand">remove_red_eye</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        <tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-tabitem>
                <e-tabitem [header]='headerText[3]'>
                    <ng-template #content>
                        <div class="tabContent">
                            <table class="fl-table">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Entity</th>
                                        <th>Date</th>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of relatedFileItems; let i = index"
                                        style="height: 15px !important;">
                                        <td style="width: 35%;"><a (click)="onNavigateToEo(item)">
                                                {{ item.CategoryName}}</a></td>
                                        <td style="width: 35%;"><a (click)="onNavigateToEo(item)">
                                                {{ item.Title}}</a></td>
                                        <td style="width: 30%;">{{ item.RelatedDate | date:'yyyy-MM-dd HH:mm'}}</td>

                                    </tr>
                                <tbody>
                            </table>



                        </div>
                    </ng-template>
                </e-tabitem>

            </e-tabitems>
        </ejs-tab>
    </mat-dialog-content>
</form>