<header-dialog (close)="onCancel()" [title]="'Phrases'"></header-dialog>

<mat-dialog-content>

    <mninput [widthControl]="300" name="searchText" [placeholder]="'search'" #search [(ngModel)]="searchText"
        (ngModelChange)="onGridFilter($event)"></mninput>

    <table class="fl-table" style="margin-top: 10px;">
        <thead>
            <tr>
                <th>Category</th>
                <th>Phrase</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of queryResult.Data; let i = index">
                <td>{{ item.CategoryName }}</td>
                <td>{{ item.Title }}</td>
                <td>
                    <button mat-raised-button color="primary" (click)="onselect(item)"> Select </button>
                </td>
            </tr>
        <tbody>
    </table>
    <div *ngIf="queryResult.Data.length==0" class="text-center">{{ "NoDataToDisplay" | translate }}</div>
    <mat-paginator #paginator [pageSize]="itemsPerPage" showFirstLastButtons (page)="onPageChange($event)"
        [length]="queryResult.Total" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>



</mat-dialog-content>