import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { PublicService } from 'src/app/core/services/publicService';
import { PublicShareService } from 'src/app/shared/service/public-share-service';

@Component({
  selector: 'app-select-systems',
  templateUrl: './select-systems.component.html',
  styleUrls: ['./select-systems.component.scss']
})
export class SelectSystemsComponent implements OnInit {

  constructor(private _http:PublicShareService, private router: Router, private publicService: PublicService,) { }
  queryResult:any[]=[]
  userRoleSubscription:Subscription
  ngOnInit(): void {
    this.onPopulateData();
  }


  onPopulateData() {
    this.userRoleSubscription = this._http.getAll('UserRoleSelectPanel/accessSystems').subscribe(res => {
      this.queryResult = res;

    });

  } 
 
}
