import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/core/services/auth.service';
import { Credentials } from 'src/app/base-class/dto/Credentials';
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  constructor( private authService: AuthService,
     private router:Router,
     private route: ActivatedRoute,
  ) { }
  model: Credentials = { UserName: "", Password: "" };
  error = "";
  returnUrl: string;
  ngOnInit() {
  this.authService.onRemoveToken();
    this.authService.logout(false);
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
  }
  onforgotPassword(){
 
    this.router.navigate(["forgotPassword"]);
  }
  submitForm(form: NgForm) {
   if(!form.valid)
  {
    this.error = "Please enter all fields";
    return;
  }

    let islogin=  this.authService.login(this.model).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.router.navigate(["selectrole"]);
        }
      }
      else
          this.error = "Could not sign in. Please check your E-Mail and Password and try again.";
    }

    );



  }

}
