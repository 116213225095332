import { Component, OnInit,EventEmitter, Input, Inject, Output } from '@angular/core';
import { RecercveFormCommentDto } from '../../Dto/form-omment-dto';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/IAppConfig';
import { ShareDataService } from '../../service/share-data-service';
import { PublicShareService } from '../../service/public-share-service';

@Component({
  selector: 'comment-view',
  templateUrl: './comment-view.component.html',
  styleUrls: ['./comment-view.component.scss']
})
export class CommentViewComponent implements OnInit {
  @Input() item: RecercveFormCommentDto;
  @Input() depth: number;

   @Output("inputComment")  inputComment = new EventEmitter<RecercveFormCommentDto>();
  imageUrl: string;
  constructor( private _http: PublicShareService,
    private shareDataService:ShareDataService,
     @Inject(APP_CONFIG) private appConfig: IAppConfig) {

    this.imageUrl = this.appConfig.imageSrc; 
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
 
  }
  // onInsertReply(parentId:string){
  //   this.entity.ExternalId=this.item.ExternalId;
  //   this.entity.FormType=this.item.;
  //   let url = "CommentPanel/Create";
  //   this.saveSubscription = this._http.Save(url, this.item, false).subscribe(
  //     (res: any) => {
  //        
  //     },
  //     (responseError: HttpErrorResponse) => {
  //       // this.errors = this._publicService.processModelStateErrors(form, responseError);
  //     });
  // }
  onInsertComment(item:RecercveFormCommentDto){ 
    this.shareDataService.DataComment=item;
    this.inputComment.emit(item);
  }

}
