import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PublicShareService } from 'src/app/shared/service/public-share-service';
 
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { PublicService } from 'src/app/core/services/publicService';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private _http: PublicShareService,
    private _publicService: PublicService,

  ) { }
  saveSubscription: Subscription;
  errors: string[] = [];
  entity={Email:""};
  ngOnInit() {
  }

  submitForm(form: NgForm) {

    if (!form.valid)
      return;
    let url = "ForgotPasswordPanel/forgotPasswordAccount";

    this.errors = [];
    this.saveSubscription = this._http.Save(url, this.entity, false).subscribe(
      (res: any) => {
        this._publicService.onSuccessMessege(res);
    
        this.router.navigate(["login"])
      },
      (responseError: HttpErrorResponse) => {
        this.errors = this._publicService.processModelStateErrors(form, responseError);
      });
  }


  ngOnDestroy(): void {
    if (this.saveSubscription != null)
      this.saveSubscription.unsubscribe();

  }


}
