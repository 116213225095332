
<div class="modal d-block mg-t-60 " style="overflow-y: auto;">
    <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bd-1 bd-grey-400 bg-transparent rounded overflow-hidden">
    <div class="modal-body pd-0">
        <div class="row no-gutters">
        <div class="col-lg-6 bg-primary">
            <div class="pd-40">
                <h3 class="tx-white  mg-b-25 mg-t-110">Welcome to ArinaOne!</h3>
                <!-- <h4 class="tx-white mg-b-20"><span>[</span> ArinaOne <span>]</span></h4>-->
                <h5 class="tx-white mg-b-15">One Source of Information</h5>
                <ul class="tx-white op-8 ">
                    <li>Workflow / Processes / Orders</li>
                    <li>Project / Tasks / Resources</li>
                    <li>Document / Content </li>
                    <li>Terminology & Phrases </li>
                    <li>Translation </li>
                    <li>And more...</li>
                </ul>
                <p class="tx-white">For more information please visit: <br/><a class="tx-bold tx-spacing-5 tx-white" href="http://www.arinasoft.com" target="_blank">www.ArinaSoft.com</a></p>
        
            <!-- <p class="tx-white op-7 mg-b-60">One Source of Information </p>
            <p class="tx-white tx-13">
                <span class="tx-uppercase tx-medium d-block mg-b-15">Our Address:</span>
                <span class="op-7">Ayala Center, Cebu Business Park, Cebu City, Cebu, Philippines 6000</span></p> -->
            </div>
        </div><!-- col-6 -->
        <div class="col-lg-6 bg-white">
            <div class="pd-xl-x-30">
                <div class="pd-x-30 pd-y-20">
                    <div class="signin-logo tx-center tx-28 tx-bold tx-inverse">
                        <img src="./assets/images/Icon_AO.png" width="100" />  <br/>
                        <span class="tx-normal">[</span> Arina<span class="tx-info">One </span> <span class="tx-normal">]</span></div>
                
                <p class="mg-t-15">Sign in to your account to continue</p>
                <form #form="ngForm" (submit)="submitForm(form)" novalidate>
                    <div class="form-group">
                        <input #username="ngModel" type="email" name="Username" [(ngModel)]="model.UserName" required class="form-control pd-y-12" placeholder="Enter your email">
                        <!-- <mat-error *ngIf="username.invalid">{{ "Email is required" | translate }}</mat-error> -->
                    </div><!-- form-group -->
                    <div class="form-group mg-b-20">
                        <input #password="ngModel" type="password" name="Password" [(ngModel)]="model.Password" required class="form-control pd-y-12" placeholder="Enter your password">
                        <!-- <mat-error *ngIf="password.invalid">{{ "Password is required" | translate }}</mat-error> -->
                        <a href="javascript:void(0);" (click)="onforgotPassword()" class="tx-12 d-block mg-t-10" >Forgot password?</a>
                    </div><!-- form-group -->

                    <button class="btn btn-primary pd-y-12 btn-block mg-b-30" (click)="submitForm(form)" [disabled]="form.invalid ">Sign In</button>
                </form>
                </div>
            </div><!-- pd-20 -->
            </div><!-- col-6 -->
        </div><!-- row -->

        </div><!-- modal-body -->
    </div><!-- modal-content -->
    </div><!-- modal-dialog -->
    <div class="tx-11" style="text-align: center;">{{ 'Copyright &copy; 2003-2024. Arinasoft AB. All Rights Reserved.' | translate}}</div>
</div>




<!-- <mat-card style="width: 30em;  margin :0px auto">
    <mat-card-title style="padding: 2%; font-size: 2em">
        <mat-icon matSuffix>lock</mat-icon>{{ "Login" | translate }}</mat-card-title>
    <mat-card-content>
        <form #form="ngForm" (submit)="submitForm(form)" novalidate>
            <div class="example-container">
                <mat-form-field>
                    <input matInput placeholder="{{ 'Enter your User Name'| translate }}" autocomplete="off" #username="ngModel" class="" name="username" [(ngModel)]="model.username" required>
                    <mat-icon matSuffix>face</mat-icon>
                    <mat-error *ngIf="username.invalid">{{ "User Name Is Required" | translate }}</mat-error>
                </mat-form-field>
            </div>

            <div class="example-container">
                <mat-form-field>
                    <input matInput placeholder="{{ 'Enter your Password'| translate }}" autocomplete="off" type="password" #password="ngModel" class="" name="password" [(ngModel)]="model.password" required>
                    <mat-icon matSuffix>vpn_key</mat-icon>
                    <mat-error *ngIf="password.invalid">{{ "Password Is Required" | translate }}</mat-error>
                </mat-form-field>
            </div>


            <mat-error *ngIf="error">
                {{error}}
            </mat-error>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="submitForm(form)" class="btn-block" [disabled]="form.invalid ">{{ "Login" | translate }}</button>
    </mat-card-actions>
</mat-card> -->