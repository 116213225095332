import { Component, OnInit, Inject } from '@angular/core';

import { TreeDTO } from 'src/app/feature-modules/base-information/Dto/UnitDto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PublicShareService } from 'src/app/shared/service/public-share-service';
import { ShareDataService } from 'src/app/shared/service/share-data-service';

@Component({
  selector: 'app-unit-tree-demo-view',
  templateUrl: './unit-tree-demo-view.component.html',

})

export class UnitTreeDemoViewComponent implements OnInit {

  treeData: Array<TreeDTO>;
  IsChackBox: boolean = false;
  constructor(private _http: PublicShareService, private shareDataService: ShareDataService,
    @Inject(MAT_DIALOG_DATA) private data,
    private _dialogRef: MatDialogRef<UnitTreeDemoViewComponent>, ) {

  }
  apiUrl: string;
  ngOnInit() {
    this.shareDataService.CheckBoxDataNode = [];

    if (this.data.IsChackBox != undefined)
      this.IsChackBox = this.data.IsChackBox;


    if (this.data.apiUrl != undefined)
      this.apiUrl = this.data.apiUrl;
    setTimeout(() => this.loadData(), 0);

  }
  loadData(): void {
    this._http.getAll(this.apiUrl).subscribe((res: Array<TreeDTO>) => {
      this.treeData = res;
    })
  }

  onClick() {
    if (this.IsChackBox) {
      this.shareDataService.DateNode={  UnitId:'',       expanded:null, ParentId: '',  UnitName:'',  UnitCode:''} ;
    
      if (this.shareDataService.CheckBoxDataNode.length > 0) {
      //  this.shareDataService.DateNode.UnitId = this.shareDataService.CheckBoxDataNode.map(c => c.UnitId).join(',');
      //  this.shareDataService.DateNode.UnitName = this.shareDataService.CheckBoxDataNode.map(c => c.UnitName).join(',')

        this._dialogRef.close( this.shareDataService.CheckBoxDataNode);
      }
    }else
        this._dialogRef.close(this.shareDataService.DateNode);


  }


  onCancel() {
    this._dialogRef.close(false);
  }
}
