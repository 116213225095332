import { Component, OnInit, OnDestroy, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { PublicShareService } from '../../service/public-share-service';
import { PublicService } from 'src/app/core/services/publicService';
import { CreateInformationDataDto } from '../../Dto/information-data-dto';
import { Subscription } from 'rxjs';
import { UnitTreeDemoViewComponent } from '../unit-tree-demo-view/unit-tree-demo-view.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'extra-information-data',
  templateUrl: './extra-information-data.component.html',
  styleUrls: ['./extra-information-data.component.scss']
})
export class ExtraInformationDataComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  ngAfterViewInit(): void {
    setTimeout(() => { 

    //  this.getOne();
    })
  }


  constructor(
    private dialog: MatDialog,
    private _http: PublicShareService,
    private _publicService: PublicService) {

  }

  @Input("ExternalId") ExternalId: string
  @Input("formType") FormType: string;
  @Input("showBaseInformation") showBaseInformation: boolean=false;
  errors: string[] = [];

  entity: CreateInformationDataDto = { InformationDataId: undefined, FormType: '', E_ModelIds: [], E_YMIds: [], E_EngineIds: [], E_GearBoxIds: [], E_FunktionGroupIds: [], ExternalId: '',  FunktionGroupName:'' };

  getRecordSubscription: Subscription;
  saveSubscription: Subscription;

  ngOnInit() {
   
  }


  OnSelect() {

    const dialogRef = this.dialog.open(UnitTreeDemoViewComponent,
      {
        disableClose: true,
        autoFocus: true,
        data: {
          apiUrl: "EnumPanel/GetTreeByEnumTypeCode?enumType=FunktionGroup",
          IsChackBox: true
        }
      });

    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.entity.E_FunktionGroupIds = res.map(c => c.UnitId);
          this.entity.FunktionGroupName = res.map(c => c.UnitName).join(',')
        }
      });
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes.ExternalId.currentValue != undefined && changes.ExternalId.currentValue != "" && changes.ExternalId.currentValue != null) {
      this.entity.ExternalId =changes.ExternalId.currentValue ;
      this.entity.FormType =changes.FormType.currentValue ;
      this.getOne();
    }

  }
  getOne() {
    
    this.getRecordSubscription = this._http.getAll("InformationDataPanel?externalId=" + this.ExternalId + "&formType=" + this.FormType).subscribe((res) => {
      this.entity = res;
    });

  }

  submitForm(form: NgForm) {
    let isEdit = false;
    if (!form.valid)
      return;
      
      this.entity.ExternalId =this.ExternalId ;
      this.entity.FormType =this.FormType ;

    let url = "InformationDataPanel/Create";
    if (this.entity.InformationDataId != null) {
      url = "InformationDataPanel/Edit";
      isEdit = true

    }
    this.errors = [];
    this.saveSubscription = this._http.Save(url, this.entity, isEdit).subscribe(
      (res: any) => {
        this._publicService.onSuccessMessege(res.Msg);
        this.entity.InformationDataId = res.InformationDataId;
      },
      (responseError: HttpErrorResponse) => {
        this.errors = this._publicService.processModelStateErrors(form, responseError);
      });
  }
  onCancel() {

  }
  ngOnDestroy(): void {
    if (this.saveSubscription != null)
      this.saveSubscription.unsubscribe();


    if (this.getRecordSubscription! != null)
      this.getRecordSubscription.unsubscribe();
  }

}
