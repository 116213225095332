import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.css']
})
export class SelectItemComponent implements OnInit, OnChanges {

  constructor() { }
  @Input("feildTitle") feildTitle: string;
  @Input("title") title: string;
  @Input('disabled') disabled: false;

  @Output("OnClick") onClick = new EventEmitter<any>();

  headerName: string
  Titel: string
  ngOnInit() {
    this.headerName = this.feildTitle;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) {
      this.Titel = changes.title.currentValue;
    }
  }
  OnClick() {
    if (this.disabled)
      return;
    this.onClick.emit();
  }

}
