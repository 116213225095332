<mat-form-field style="margin-right: 10px;width: 100%;">
    <mat-label>{{ placeholder | translate }}</mat-label>
    <!-- [(placeholder)]="placeholder" -->
    <input matInput [formControl]="appDatePickerControl" [matDatepicker]="picker" [required]="isRequired" (dateChange)="onChange($event.target.value)" [(ngModel)]="value" (keyup)="onKey($event)" (input)="onChange($event.target.value)"
        (blur)="blurred();">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="appDatePickerControl.invalid && (appDatePickerControl.dirty || appDatePickerControl.touched)">
        <div *ngIf="appDatePickerControl.errors.required">    {{ "This is a required field" | translate }}</div>
    </mat-error>

</mat-form-field>
