import { Component, OnInit, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { PublicService } from 'src/app/core/services/publicService';
import { PublicShareService } from 'src/app/shared/service/public-share-service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserInfoDto } from 'src/app/base-class/dto/UserRoleDto';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/IAppConfig';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EditUserProfileComponent } from '../edit-user-profile/edit-user-profile.component';
import { BrowserStorageService } from 'src/app/core/services/browser-storage.service';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'], animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})

export class AdminLayoutComponent implements OnInit {


  constructor(private router: Router,
    private _http: PublicShareService,
    private authService: AuthService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private permissionsService: NgxPermissionsService,
    private browserStorageService: BrowserStorageService,
    private dialog: MatDialog,
    private publicservice: PublicService, public translate: TranslateService
  ) {
    this.setPermission();
  }
  srchString: string;
  navItems: NavItem[] = [];
  serverUrl: string;
  lang: string;
  entity: UserInfoDto = { FullName: '', Language: null, Theme: null, ImagePath: '', RoleName: '', UnitName: '', UserId: '', IsNewPassword: false };

  ngOnInit() {

    this.serverUrl = this.appConfig.imageSrc;
    this._http.getAll("Permissions/AccessMenu").subscribe((res: any) => {


      this.navItems = this._getPreparedData(res)

    });

    this.getUserInfo();
  }

  getUserInfo() {

    this._http.getAll("MyAccount/GetAccountInfo").subscribe((res: UserInfoDto) => {
      this.entity = res;
      this.publicservice.currentUserId = res.UserId;
      sessionStorage.setItem("currentUserId", res.UserId);
      sessionStorage.setItem("currentUserName", res.FullName)
      if (res.Language != null) {
        this.translate.use(res.Language);
        this.lang = res.Language;
      }
      else {
        this.translate.setDefaultLang('en');
        this.lang = this.translate.getDefaultLang();
      }

      if (res.IsNewPassword == true) {
        this.onShowProfile();
      }
    });

  }

  onChangeLang(value: string) {

    this.lang = value;
    this.translate.use(value);
  }



  onShowProfile() {

    const dialogRef = this.dialog.open(EditUserProfileComponent, {
      disableClose: true,
      autoFocus: true,
      data:{IsNewPassword:this.entity.IsNewPassword}
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res)
          this.getUserInfo();
      });
  }


  onlogOut() {
    this.authService.logout(true).subscribe((res) => {
      this.router.navigate(['']);
      this.permissionsService.flushPermissions();
    })

  }

  async setPermission() {
    // let x = <any>await this._http.getUserPermissionPromiss("Permissions/PermissionWithRole");
    // console.log(x);
  }

  private onloaddata() {
  }






  private _getPreparedData(result) {
    const menuItems = result.Items.map(x => Object.assign([], x));
    menuItems.filter((navItem) => navItem.Url != null).forEach(item => {
      switch (item.ExternalId) {
        case 'EduSystem':
          //  item.Url = result.ArinaOneEducationUrl+  item.Url
          item.Url = result.ArinaOneEducationUrl + "/sign-in?redirectURL=" + encodeURIComponent(item.Url) + "&t=" + this.browserStorageService.getLocal("access_token")// encodeURIComponent(this.browserStorageService.getLocal("access_token"))
          break;
        case 'ArinaSite':

          item.Url = result.ArinaSiteUrl + "account/SignIn?redirectURL=" + encodeURIComponent(item.Url) + "&t=" + this.browserStorageService.getLocal("access_token")// encodeURIComponent(this.browserStorageService.getLocal("access_token"))
          break;
      }
    })
    const tree = menuItems.filter((navItem) => navItem.ParentId === null);

    function populateMenuChildren(node) {
      const { MenuId } = node;
      menuItems.forEach((navItem) => {
        if (navItem.ParentId === MenuId) {
          if (!node.children)
            node.children = [];

          node.children.push(navItem);

        }
      });
    }
    tree.forEach((question) => {
      populateMenuChildren(question);
    });

    return tree;

  }








  ngAfterViewInit() {

  }

  public logout() {

    this.router.navigate(['login'], { replaceUrl: true });
  }

}
export interface NavItem {
  Title: string;
  disabled?: boolean;
  iconName: string;
  Url?: string;
  children?: NavItem[];
}
