<header-dialog (close)="onCancel()" [title]="'Document'"></header-dialog>

<div class="br-pagetitle bg-gray-100 pd-t-5 pd-b-5" style="border-bottom: 1px solid #ddd !important;"
    *ngIf="fileType=='AODC' || fileType=='AOTMP' || fileType=='AOMRG'">
    <div *ngIf="isEditable">
        <a class="btn btn-primary btn-with-icon mg-r-5" (click)="saveAsSfdt(false)" *ngxPermissionsOnly="['FileUpdate']"
            title="Save document">
            <div class="ht-25" title="{{ 'Save document' | translate }}">
                <span class="pd-x-10 tx-white tx-spacing-2 btn-settings-font">{{ "Save" | translate }}</span>
            </div>
        </a>
        <a class="btn btn-primary btn-with-icon mg-r-5" (click)="saveAsSfdt(true)" *ngxPermissionsOnly="['FileUpdate']"
            title="Check in this document">
            <div class="ht-25" title="{{ 'Check in this document' | translate }}">
                <span class="pd-x-10 tx-white tx-spacing-2 btn-settings-font">{{ "Check In" | translate }}</span>
            </div>
        </a>
    </div>
    <a class="btn btn-primary btn-with-icon mg-r-5" (click)="export()" *ngxPermissionsOnly="['FileUpdate']"
        title="Download as Word Document">
        <div class="ht-25" title="{{ 'Download as Word Document' | translate }}">
            <span class="pd-x-10 tx-white tx-spacing-2 btn-settings-font">{{ "Download as Word Document" | translate
                }}</span>
        </div>
    </a>
    <a class="btn btn-danger btn-with-icon mg-r-5" (click)="onCancel()" *ngxPermissionsOnly="['FileUpdate']"
        title="Close document">
        <div class="ht-25" title="{{ 'Close document' | translate }}">
            <span class="pd-x-10 tx-white tx-spacing-2 btn-settings-font">{{ "Cancel" | translate }}</span>
        </div>
    </a>

</div>


<mat-dialog-content style="height: 90vh;">

    <div class="control-section">
        <div *ngIf="fileType=='AOXLS'">
            <ejs-spreadsheet #aoSpreadsheet allowOpen='true' allowSave='true' (created)="XLScreated()"
                (fileMenuItemSelect)="fileMenuItemSelect($event)" (fileMenuBeforeOpen)="fileMenuBeforeOpen()">
            </ejs-spreadsheet>
            <!-- <ejs-spreadsheet #aoSpreadsheet allowOpen='true' allowSave='true'
            (beforeOpen)="beforeOpen($event)"
            (created)="XLScreated()" (fileMenuItemSelect)="fileMenuItemSelect($event)" (fileMenuBeforeOpen)="fileMenuBeforeOpen()"> </ejs-spreadsheet> -->
        </div>

        <div class="control-section" [ngClass]="{'panel-mail-merge':fileType=='AOMRG'}"
            *ngIf="fileType=='AODC' || fileType=='AOTMP'  || fileType=='AOMRG'">
            <ejs-documenteditorcontainer #documenteditor_default (toolbarClick)="onToolbarClick($event)"
                [serviceUrl]="serverUrl+'DocumentSettingPanel/'" [enableToolbar]=isEditable (created)="DCcreated()"
                [enableSpellCheck]=false (selectionChange)="selectionChange($event)"> </ejs-documenteditorcontainer>
        </div>
        <div style="width: 15%;" *ngIf="fileType=='AOMRG'">


            <ul cdkDropList #todoList="cdkDropList" [cdkDropListData]="mergeMailLists"
                (cdkDropListDropped)="drop($event)">
                <li *ngFor="let item of mergeMailLists">
                    <a mat-button class="listview" [id]="item.FieldName" cdkDrag
                        (click)="onAddMailMerge(item)">{{item.FieldName}}</a>

                </li>
            </ul>


            <!-- 
            {{mergeMailLists |json}} -->
        </div>
    </div>
</mat-dialog-content>