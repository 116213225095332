import { APP_INITIALIZER, NgModule, Inject } from '@angular/core';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AccessDeniedComponent } from './public-component/access-denied/access-denied.component';
import { AdminLayoutComponent } from './public-component/admin-layout/admin-layout.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { LayoutLoginComponent } from './public-component/layout-login/layout-login.component';
import { LoginComponent } from './public-component/login/login.component';
import { ForgotPasswordComponent } from './public-component/forgot-password/forgot-password.component';
import { PageNotFoundComponent } from './public-component/page-not-found/page-not-found.component';
import { PublicShareService } from './shared/service/public-share-service';
import { SelectedUserRoleComponent } from './public-component/selected-user-role-component/selected-user-role-component.component';
import { SharedModule } from './shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { EditUserProfileComponent } from './public-component/edit-user-profile/edit-user-profile.component';
import {FileManagerModule} from '@syncfusion/ej2-angular-filemanager';
import { SelectSystemsComponent } from './public-component/select-systems/select-systems.component';
export function HttpLoaderFactory(httpClient: HttpClient  ) {
  return new TranslateHttpLoader(httpClient );
}

@NgModule({
  entryComponents:[EditUserProfileComponent, ],
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    LayoutLoginComponent,
    PageNotFoundComponent,
    AdminLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    EditUserProfileComponent,
    SelectedUserRoleComponent,
    SelectSystemsComponent,
    
  ],
  imports: [
    BrowserModule,
     AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    FileManagerModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
     
    }),
    NgxPermissionsModule.forRoot(),
   
  ],
  providers: [
    {
         provide: APP_INITIALIZER,
         useFactory: (ds: PublicShareService, ps: NgxPermissionsService ) => async function() {
          
          return await ds.getUserPermissionPromiss("Permissions/PermissionWithRole").then((data) => {
       if(data)
           return  ps.loadPermissions(data)})},
         deps: [PublicShareService, NgxPermissionsService],
         multi: true
       },
      // {  provide: HTTP_INTERCEPTORS, useClass: SpinnerLoadingInterceptor, multi: true},
     ],
  bootstrap: [AppComponent]
})
export class AppModule { }
