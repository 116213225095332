import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentEditorContainerComponent, ToolbarService, SfdtExportService, CustomToolbarItemModel, SelectionService, ListDialogService, EditorService, SelectionChangeEventArgs, TextFormFieldInfo, FormFieldData, FormField } from '@syncfusion/ej2-angular-documenteditor';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublicShareService } from 'src/app/shared/service/public-share-service';
import { Subscription } from 'rxjs';
import { PublicService } from 'src/app/core/services/publicService';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateFileDTO } from 'src/app/feature-modules/base-information/Dto/FileDto';
import { BeforeOpenEventArgs, BeforeSaveEventArgs, MenuSelectEventArgs, SpreadsheetComponent } from '@syncfusion/ej2-angular-spreadsheet';
import { IAppConfig, APP_CONFIG } from 'src/app/core/services/IAppConfig';
import { createElement } from "@syncfusion/ej2-base";
import { ClickEventArgs, MenuItemModel } from '@syncfusion/ej2-angular-navigations';
import { TranslationDlgComponent } from '../translation-dlg/translation-dlg.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { GridRequest, GridResult } from 'src/app/base-class/grid-classes';
import { getUid } from '@syncfusion/ej2-angular-gantt';

@Component({
  selector: 'app-doc-editor',
  templateUrl: './doc-editor.component.html',
  styleUrls: ['./doc-editor.component.scss'],
  providers: [ToolbarService, SfdtExportService, SelectionService, ListDialogService, EditorService]
})
export class DocEditorComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(@Inject(APP_CONFIG) private appConfig: IAppConfig,
    private dialog: MatDialog,
    private _dialogRef: MatDialogRef<DocEditorComponent>,
    private _publicService: PublicService,
    private _http: PublicShareService,
    @Inject(MAT_DIALOG_DATA) private data) { }

  public fileType: string = this.data.fileType;

  roleList: any[] = [];
  serverUrl: string = this._http.serverUrl
  roleSubscription: Subscription

  @ViewChild('aoSpreadsheet')
  public XLScontainer: SpreadsheetComponent;

  @ViewChild('documenteditor_default')
  public container: DocumentEditorContainerComponent;
  saveSubscription: Subscription;
  phraseSubscription: Subscription;
  Id: string = undefined;
  fileVersion: string = "1.0";
  isEdit: boolean = false;
  bDraft: boolean = false;
  isEditable: boolean = true;
  entity: CreateFileDTO = { ItemName: '', ItemDescription: '', E_LanguageId: '', E_DocStatusId: '', EntityType: '', fType: '', TemplateId: 0 };
  EntityType: string
  formType: string = "";
  mergeMailLists: any[] = [];
  docPhrases: string[] = [];
  query: GridRequest = { PageSize: 10, Page: 1, Sort: [], Filters: null }
  queryResult = new GridResult<any>(0, []);
  ngOnInit(): void {

    if (this.data.FileId != undefined) {
      this.Id = this.data.FileId;
      this.fileVersion = this.data.fileVersion;
      this.isEdit = true;
      this.bDraft = this.data.bDraft;
      this.fileType = this.data.fileType
    }
    if (!this.data.bEditable)
      this.isEditable = false;
  }


  public toolItem: CustomToolbarItemModel = {
    prefixIcon: "e-de-ctnr-plus-small",
    tooltipText: "Save Select Phrases",
    text: "Save Phrases",
    id: "SavePhrases"
  };


  toolbarMergeDocument: CustomToolbarItemModel = {
    prefixIcon: 'sf-icon-FinishMerge',
    tooltipText: 'Merge Document',
    text: this.onWrapText('Merge Document'),
    id: 'MergeDocument'
  }
  getPhrases() {
    let formFieldsNames: string[] = this.container.documentEditor.getFormFieldNames();
    for (var i = 0; i < formFieldsNames.length; i++) {
      this.docPhrases.push(this.container.documentEditor.getFormFieldInfo(formFieldsNames[i]).helpText);
    }
    let phIds: string = this.docPhrases.join("@");
    this.phraseSubscription = this._http.GetByQS("PhrasesPanel/GetPhraseTransWithDTO", "phraseIds=" + phIds + "&languageId=" + this.entity.E_LanguageId).subscribe((res: any) => {
      debugger;
      let tst: any = res;

      for (var i = 0; i < formFieldsNames.length; i++) {
        let ff = this.container.documentEditor.getFormFieldInfo(formFieldsNames[i]);



        let textfieldInfo: TextFormFieldInfo = this.container.documentEditor.getFormFieldInfo(formFieldsNames[i]) as TextFormFieldInfo;
        textfieldInfo.defaultValue = res[0].Title;
        textfieldInfo.enabled = false;
        this.container.documentEditor.setFormFieldInfo(formFieldsNames[i], textfieldInfo);

      }

    })

    // let formFieldDate: FormFieldData[] = this.container.documentEditor.exportFormData();
    // let formFieldsNames: string[] = this.container.documentEditor.getFormFieldNames();
    // for (var i = 0; i < formFieldsNames.length; i++) {
    //   alert(this.container.documentEditor.getFormFieldInfo(formFieldsNames[i]).helpText);
    // }
  }
  onWrapText(text: string): string {
    let content: string = '';
    let index: number = text.lastIndexOf(' ');
    content = text.slice(0, index);
    text.slice(index);
    content += '<div class="e-de-text-wrap">' + text.slice(index) + '</div>';
    return content;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.isEdit)
        this.getOne();
      this.getRoleList()

    })
    if (!this.container.toolbarItems.some(c => c["id"] == this.toolItem.id))
      this.container.toolbarItems.push(this.toolItem)

    if (this.fileType == "AOMRG")
      if (!this.container.toolbarItems.some(c => c["id"] == this.toolbarMergeDocument.id))
        this.container.toolbarItems.push(this.toolbarMergeDocument)




  }



  addContextMenu() {

    let menuItems: MenuItemModel[] = [
      {
        text: 'Add from phrase bank',
        id: 'add_translation',
        iconCss: 'e-icons e-de-ctnr-plus'
      },

      {
        text: 'Save as phrase',
        id: 'SavePhrases'
      }
    ]


    this.container.documentEditor.contextMenu.addCustomMenu(menuItems, false);

    this.container.documentEditor.customContextMenuSelect = (args: any): void => {
      let id: string = this.container.documentEditor.element.id;
      switch (args.id) {
        case id + 'add_translation':
          this.onOpenTranslation();
          break;
        case id + 'SavePhrases':
          this.onSavePhrases()
          break;
      }


    }
  };
  i: number = 0
  onOpenTranslation() {
    let searchContent: string = this.container.documentEditor.selection.text;
    const dialogRef = this.dialog.open(TranslationDlgComponent, {
      disableClose: false,
      width: '700px',
      height: '650px',
      autoFocus: true,
      data: { searchText: searchContent, E_LanguageId: this.entity.E_LanguageId },
    });

    dialogRef.afterClosed().subscribe(
      res => {
        if (res != null)
          this.i++
        // this.container.documentEditor.editor.insertText(` ${res} `)
        {
          let fieldCode: string = res.substring(0, res.indexOf("##"));
          let fieldResult: string = '«' + res.substring(res.indexOf("##") + 2) + '»';
          // this.container.documentEditor.editor.insertField(fieldCode, fieldResult);

          // this.container.documentEditor.updateFields();

          this.container.documentEditor.editor.insertFormField('Text');
          // Set text form field properties
          let textfieldInfo: TextFormFieldInfo = this.container.documentEditor.getFormFieldInfo('Text1') as TextFormFieldInfo;
          textfieldInfo.defaultValue = fieldResult;
          // textfieldInfo.format = "Uppercase";
          textfieldInfo.type = "Text";
          textfieldInfo.helpText = fieldCode;
          this.container.documentEditor.setFormFieldInfo('Text1', textfieldInfo);

        }


      });
  }

  onChangeEntityType(entityTypeId: string) {
    if (entityTypeId != "00000000-0000-0000-0000-000000000000") {
      this._http.getAll(`EnumPanel/GetTableColumnData?enumId=${entityTypeId}`).subscribe(res => {
        this.mergeMailLists = res

      })
    }
  }


  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      this.container.documentEditor.selection.select({ x: event.distance.x, y: event.distance.y, extend: false });

      this.insertField(this.mergeMailLists[event.currentIndex].FieldName, this.mergeMailLists[event.currentIndex].FieldName);

    }
  }

  insertField(fieldCodeName: any, fieldName: any,): void {
    let fileName: any = fieldName.replace(/\n/g, '').replace(/\r/g, '').replace(/\r\n/g, '');
    let fieldCode: any = 'MERGEFIELD  ' + fieldCodeName + '  \\* MERGEFORMAT ';
    this.container.documentEditor.editor.insertField(fieldCode, '«' + fieldName + '»');
    this.container.documentEditor.focusIn();
  }

  selectionChange(event: SelectionChangeEventArgs) {
    // alert(this.container.documentEditor.selection.getFieldInfo().code);

    // alert(this.container.documentEditor.selection.getCurrentFormField().formFieldData.helpText);

  }

  onAddMailMerge(maileMerge: any) {
    this.insertField(maileMerge.FieldName, maileMerge.FieldName);

  }

  public onToolbarClick(args: ClickEventArgs): void {

    switch (args.item.id) {
      case 'SavePhrases':
        this.onSavePhrases()
        break;
      case 'MergeDocument':
        this.mergeDocument();
        break;

    }
  };



  mergeDocument(): void {
    this.container.documentEditor.saveAsBlob('Docx').then((blob: Blob) => {

      let exportedDocumment: Blob = blob;
      let fileReader: any = new FileReader();
      fileReader.onload = (): void => {
        let base64String: any = fileReader.result;
        let responseData: any = {
          fileName: this.container.documentEditor.documentName + '.docx',
          documentData: base64String
        };

        //this.showHideWaitingIndicator(true);
        let baseUrl: string = this.serverUrl + 'DocumentSettingPanel/MailMerge';
        let httpRequest: XMLHttpRequest = new XMLHttpRequest();
        httpRequest.open('POST', baseUrl, true);
        httpRequest.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        httpRequest.onreadystatechange = () => {
          if (httpRequest.readyState === 4) {
            if (httpRequest.status === 200 || httpRequest.status === 304) {
              this.container.documentEditor.open(httpRequest.responseText);
            } else {
              // Failed to merge document
              alert("Information   failure to merge document")

            }
            // this.showHideWaitingIndicator(false);
          }
        };
        httpRequest.send(JSON.stringify((<any>responseData)));
      };
      fileReader.readAsDataURL(blob);
    });
  }


  // showHideWaitingIndicator(show: boolean): void {
  //   let waitingPopUp: HTMLElement = document.getElementById('waiting-popup');
  //   let inActiveDiv:HTMLElement = document.getElementById('popup-overlay');
  //   inActiveDiv.style.display = show ? 'block' : 'none';
  //   waitingPopUp.style.display = show ? 'block' : 'none';
  // }


  onSavePhrases() {
    let selectContent: string = this.container.documentEditor.selection.text;

    if (selectContent == "") {
      this._publicService.onWarningMessege("Content not selected!")
      return;
    }
    let phrases = { Translation: selectContent, E_LanguageId: this.entity.E_LanguageId };
    this._http.Save("PhrasesPanel/CreatePhrasesTranslation", phrases, false).subscribe((res: any) => {
      this._publicService.onSuccessMessege(res)
    })
  }

  getRoleList() {
    this.roleSubscription = this._http.getAll("RolePanel/RoleList").subscribe((res: any[]) => {
      this.roleList = res;
      res.forEach(item => {
        this.container.documentEditor.documentHelper.restrictEditingPane.usersCollection.push(item.RoleName)

      })
    })
  }

  getOne() {
    let data = this.Id + "_" + this.fileVersion + "_" + this.bDraft;
    this.saveSubscription = this._http.getItembyId("", data).subscribe(
      (res: any) => {
        this.entity.ItemName = res.Details.Name;
        this.entity.ItemDescription = res.Details.Description;
        this.entity.E_DocStatusId = res.Details.E_DocStatusId;
        this.entity.fType = res.Details.fType;
        this.entity.E_LanguageId = res.Details.E_LanguageId
        this.entity.EntityType = res.Details.EntityType

        if (this.entity.EntityType)
          this.onChangeEntityType(this.entity.EntityType);

        if (res.Details.fContent) {


          if (res.Details.fType == "AOXLS")
            this.XLScontainer.openFromJson({ file: res.Details.fContent });
          else {
            this.container.documentEditor.open(res.Details.fContent);
            this.container.documentEditor.isReadOnly = !this.isEditable
            if (!this.isEditable) {
              this.container.enableTrackChanges = false;
            }

            this.container.documentEditor.currentUser = res.CurrentUserRole
            this.container.documentEditor.userColor = '#333';
            this.container.showPropertiesPane = this.isEditable;
            this.getPhrases();
          }
        }


      },
      (responseError: HttpErrorResponse) => {

      });
  }
  onCancel() {
    this._dialogRef.close(false);
  }
  export(): void {
    this.container.documentEditor.save('tmp', 'Docx');
  }
  save(bCheckIn: boolean) {
    // let iDetails = {ItemName:this.entity.ItemName,ItemDescription:this.entity.ItemDescription,E_DocStatusId:this.entity.E_DocStatusId}
    // let itemPermissions = [];
    // this.data.fmObject.itemData[0].itemDetails=iDetails;
    // this.data.fmObject.itemData[0].itemPermissions=itemPermissions;
    // let indata = {path:JSON.stringify(this.data.fmObject.pathNames), data:'',itemdata:JSON.stringify(this.data.fmObject.itemData),fileId:this.Id,fileVersion:this.fileVersion,bCheckIn:false};

    let token = JSON.parse(localStorage.getItem('access_token'));
    this.XLScontainer.saveAsJson().then(Json => (fetch(this.appConfig.apiEndpointFMGen + '/sveXls', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + token,
      },
      body: JSON.stringify({
        name: "Tst", JSONData: JSON.stringify((Json as any).jsonObject),
        path: JSON.stringify(this.data.fmObject.pathNames), itemdata: JSON.stringify(this.data.fmObject.itemData),
        fileId: this.Id, fileVersion: this.fileVersion, bCheckIn: bCheckIn
      }),
    })
      .then((response) => {
        response.json();
        this._publicService.onSuccessMessege("Document was saved successfully");
        this._dialogRef.close(true);
      })
    ))

    // this.XLScontainer.save({ url: 'http://localhost:2517/api/FileManagerPanel/sveCnt', fileName: 'Worksheet', saveType: 'Xlsx' });
    // let tst:any;
    //   // this.XLScontainer.saveAsJson().then(Json => (tst = Json));
    //   alert(tst);
  }
  saveAsSfdt(bCheckIn: boolean): void {

    //  
    // let item1=    this.container.documentEditor.selection.getFieldCode()
    // this.container.documentEditor.save('tmp','Docx');

    // let XLSsfdt:any=this.XLScontainer.

    let sfdt: any = this.container.documentEditor.serialize();
    let iDetails = { ItemName: this.entity.ItemName, ItemDescription: this.entity.ItemDescription, E_DocStatusId: this.entity.E_DocStatusId }
    let itemPermissions = [];
    this.data.fmObject.itemData[0].itemDetails = iDetails;
    this.data.fmObject.itemData[0].itemPermissions = itemPermissions;
    let data = { path: JSON.stringify(this.data.fmObject.pathNames), data: JSON.stringify(sfdt), itemdata: JSON.stringify(this.data.fmObject.itemData), fileId: this.Id, fileVersion: this.fileVersion, bCheckIn: bCheckIn };
    this.saveSubscription = this._http.SaveContent(data).subscribe(
      (res: any) => {
        this._publicService.onSuccessMessege("Document was saved successfully");
        this._dialogRef.close(true);
      });
  }
  DCcreated() {
    // this.container.documentEditor.showDialog('List');
    this.container.documentEditor.spellChecker.languageID = 1033 //LCID of "en-us";
    this.container.documentEditor.spellChecker.removeUnderline = false;
    this.container.documentEditor.spellChecker.allowSpellCheckAndSuggestion = true;

    this.addContextMenu();

  }
  XLScreated() {
    // this.XLScontainer.hideFileMenuItems(['Save As']);
    if (this.isEditable) {
      this.XLScontainer.addFileMenuItems([{ id: 'save', text: 'Save', iconCss: 'e-save e-icons' }], 'Open');
      this.XLScontainer.addFileMenuItems([{ id: 'checkin', text: 'Check In', iconCss: 'e-save e-icons' }], 'Save');
    }

    if (this.fileType == "AOXLS") {
      //  this.XLScontainer.addFileMenuItems(
      //      [{
      //          text: 'Download As', iconCss: 'e-save e-icons', items: [{ id:'xlsx', text: 'Microsoft Excell     (.xlsx)', iconCss: 'e-xlsx e-icons' },
      //              { id:'xls', text: 'Microsoft Excell 97-2003     (.xls)', iconCss: 'e-xls e-icons' }, { id:'csv', text: 'Comma-separeted values     (.csv)', 
      //              iconCss: 'e-csv e-icons' }, { id:'pdf', text: 'PDF document     (.pdf)', iconCss: 'e-pdf e-icons' }]
      //      }],
      //      'New');
    }
    else {
      this.XLScontainer.addFileMenuItems(
        [{
          text: 'Download As', iconCss: 'e-save e-icons', items: [{ text: 'Word Document', iconCss: 'e-doc e-icons' }]
        }],
        'Check In');
    }

  }
  fileMenuBeforeOpen(): void {
    this.XLScontainer.hideFileMenuItems(['Save As']);
  }
  fileMenuItemSelect(args: MenuSelectEventArgs): void {
    switch (args.item.id) {
      // case 'Import': select(`#${this.XLScontainer.element.id}_fileUpload`, this.XLScontainer.element).click();
      //     break;
      case 'save':
        if (this.fileType == "AOXLS")
          this.save(false);
        else
          this.saveAsSfdt(false);
        break;
      case 'checkin':
        if (this.fileType == "AOXLS")
          this.save(true);
        else
          this.saveAsSfdt(true);
        break;
      case 'xlsx':
        // this.XLScontainer.save({ saveType: 'Xlsx' });
        // args.cancel = true; 
        this.SaveAsExcel();
        break;
      case 'xls': this.XLScontainer.save({ saveType: 'Xls' });
        break;
      case 'csv': this.XLScontainer.save({ saveType: 'Csv' });
        break;
      case 'pdf': this.XLScontainer.save({ saveType: 'Pdf' });
        break;
      case 'lst':
        this.container.documentEditor.showDialog('List');
        break;
    }
  }
  beforeOpen(args: BeforeOpenEventArgs) {
    let token = JSON.parse(localStorage.getItem('access_token'));
    args.requestData["headers"] = {
      Authorization: "Bearer " + token
    };
  }
  SaveAsExcel() {
    // let token = JSON.parse(sessionStorage.getItem('access_token'));
    //   this.XLScontainer.saveAsJson().then(response => { 
    //     // var formData = new FormData(); 
    //     // formData.append( 
    //     //   "JSONData", 
    //     //   JSON.stringify(response.jsonObject.Workbook) 
    //     // ); 
    //     // formData.append("fileName", "Sample"); 
    //     // formData.append("saveType", "Xlsx"); 
    //     //  
    //     fetch( 
    //       this.appConfig.apiEndpointFMGen + '/downloadXls', 
    //       { 
    //         method: "POST", 
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization':'bearer '  + token,
    //       }, 
    //         body: JSON.stringify({ fileName: "Sample",saveType: "Xlsx", JSONData: JSON.stringify(response.jsonObject.Workbook)}),
    //       } 
    //     ).then(response => { 
    //       response.blob().then(data => { 
    //         var anchor = createElement("a", { 
    //           attrs: { download: "Sample.xlsx" } 
    //         }); 
    //         var url = URL.createObjectURL(data); 
    //         anchor.href = url; 
    //         document.body.appendChild(anchor); 
    //         anchor.click(); 
    //         URL.revokeObjectURL(url); 
    //         document.body.removeChild(anchor); 
    //       }); 
    //     }); 
    //   }); 
  }


  ngOnDestroy(): void {
    if (this.roleSubscription)
      this.roleSubscription.unsubscribe();
  }
}
