<div style="width: 600px;  margin: 0px auto;">


  <div *ngIf="isShowGrid else isMassege">
    <div class="txtform">Select Role</div>

    <table class="fl-table">
      <thead>
        <tr>
          <th> # </th>
 
          <th *ngFor="let columnItem of columns">
            <grid-filter [column]="columnItem" (onFilter)="onGridFilter($event)" (onSort)="onGridSort($event)">
            </grid-filter>
          </th>

          <th> Select Role</th>
        </tr>

      </thead>
      <tbody>
        <tr *ngFor="let item of queryResult.Data; let i = index">
          <td>{{ itemsPerPage * (currentPage) + i + 1 }}</td>
          <td>{{ item.UnitName }}</td>
          <td>{{ item.RoleTitle }}</td>

          <td>
            <button mat-raised-button color="primary" (click)="onSelectUserRole(item)"> Select </button>
          </td>
        </tr>
      <tbody>
    </table>
 
    <mat-paginator #paginator [pageSize]="itemsPerPage" showFirstLastButtons (page)="onPageChange($event)"
      [length]="queryResult.Total" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

  </div>
</div>
<ng-template #isMassege>
<h2>
  Transferring to the System
</h2>
</ng-template>