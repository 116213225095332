




import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';


import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { IAppConfig, APP_CONFIG } from 'src/app/core/services/IAppConfig';
import { GridRequest, GridResult } from 'src/app/base-class/grid-classes';



@Injectable()
export class PublicShareService {
  urlImagePath: string;
  serverUrl: string;
  constructor(@Inject(APP_CONFIG) private appConfig: IAppConfig, private _http: HttpClient) {
    this.urlImagePath = appConfig.imageSrc
    this.serverUrl = this.appConfig.apiEndpoint + this.appConfig.apiSettingsPath
  }

  downloadFiles(url: string, data: string): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http.post<any>(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}`, data, { headers: headers }).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }
  getItembyId(url: string, data: string): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http.get<any>(`${this.appConfig.apiEndpointFMOne}?args=${data}`, { headers: headers }).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }
  getItemsByType(data: string): Observable<any> {
    debugger;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http.get<any>(`${this.appConfig.apiEndpointFMGen}/gItype?${data}`, { headers: headers }).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }
  SaveFolder(url: string, data: string): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http
      .post(`${this.appConfig.apiEndpointFM}`, data, { headers: headers })
      .pipe(
        map(response => response || {}),
        catchError(this.handleError));
  }
  SaveXLS(data: any): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http
      .post(`${this.appConfig.apiEndpointFMGen}/sveXls`, data, { headers: headers })
      .pipe(
        map(response => response || {}),
        catchError(this.handleError));
  }
  SaveContent(data: any): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http
      .post(`${this.appConfig.apiEndpointFMGen}/sveCnt`, data, { headers: headers })
      .pipe(
        map(response => response || {}),
        catchError(this.handleError));
  }
  getDeletedItems(iPageNo: number, iPageSize: number): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http.get<any>(`${this.appConfig.apiEndpointFMDeleted}?bDeleted=true&iPage=` + iPageNo + '&iPageSize=' + iPageSize, { headers: headers }).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }
  onRecBin(bRS: boolean, id: string, nme: string, type: string): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http.post<any>(`${this.appConfig.apiEndpointFMGen}/RecBin?bRS=` + bRS + '&id=' + id + '&nme=' + nme + '&type=' + type, { headers: headers }).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }
  getLatestDocs(pId: string): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http.get<any>(`${this.appConfig.apiEndpointFMGen}/gRD?pId=` + pId + '&cId=0', { headers: headers }).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }

  getbyId(url: string, id: string): Observable<any> {

    return this._http.get<any>(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}/${id}`).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }

  getAll(url: string, header?: any): Observable<any> {

    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (header)
      headers = headers.append("ExternalCode", header)
    return this._http.get<any>(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}`).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }

  getXML(url: string): Observable<any> {
    const headers = new HttpHeaders({ "Accept": "application/xml" });
    return this._http.get<any>(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}`, { headers: headers }).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }

  GetByQS(url: string, qs: string, header?: any): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (header)
      headers = headers.append("ExternalCode", header)
    return this._http.get<any>(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}?${qs}`).pipe(
      map((result: any) => { return result }),
      catchError(this.handleError));
  }

  getGridPage(url: string, query: GridRequest, parameterQs: string, header?: any): Observable<GridResult<any>> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (header)
      headers = headers.append("ExternalCode", header)
    let sort = '';
    let filter = '';
    if (query.Sort.length > 0)
      sort = encodeURIComponent(JSON.stringify(query.Sort));

    if (query.Filters !== null)
      filter = encodeURIComponent(JSON.stringify(query.Filters));


    let queryString = 'Page=' + query.Page + '&PageSize=' + query.PageSize
      + '&Filter=' + filter + '&Sort=' + sort;
    if (parameterQs)
      queryString += "&" + parameterQs;

    return this._http
      .get<GridResult<any>>(
        `${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}?${queryString}`, { headers: headers }
      ).pipe(
        map(result => {
          if (result) {
            return <GridResult<any>>{
              Total: result.Total,
              Data: result.Data
            };
          }
          else {
            return <GridResult<any>>{
              Total: 0,
              Data: null
            };
          }
        }), catchError(this.handleError));

  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    console.error("observable error: ", error);
    return observableThrowError(error);
  }

  SaveByQS(url: string, qs: string): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this._http
      .post(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url + qs}`, { headers: headers })
      .pipe(
        map(response => response || {}),
        catchError(this.handleError));
  }
  Save(url: string, entity: any, editmode: boolean, header?: any): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (header)
      headers = headers.append("ExternalCode", header)

    if (!editmode)
      return this._http
        .post(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}`, entity, { headers: headers })
        .pipe(
          map(response => response || {}),
          catchError(this.handleError));
    else
      return this._http
        .put(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}`, entity, { headers: headers })
        .pipe(
          map(response => response || {}),
          catchError(this.handleError));
  }

  onDelete(url: string, id: String, header?: any): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (header)
      headers = headers.append("ExternalCode", header)

    return this._http
      .delete(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}/${id}`, { headers: headers })
      .pipe(
        map(response => response || {}),
        catchError(this.handleError));
  }


  onDeleteByQueryString(url: string, qs: string, header?: any): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (header)
      headers = headers.append("ExternalCode", header)

    return this._http
      .delete(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url + qs}`, { headers: headers })
      .pipe(
        map(response => response || {}),
        catchError(this.handleError));
  }

  uploadFile(url: string, file: any): Observable<any> {
    return this._http
      .post(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}`, file, { reportProgress: true, observe: 'events' })
      .pipe(
        map(response => response || {}),
        catchError(this.handleError));
  }


  getUserPermissionPromiss(url: string): Promise<any> {
    if (localStorage.getItem("IsLog") != null)
      return this._http.get<string[]>(`${this.appConfig.apiEndpoint}${this.appConfig.apiSettingsPath + url}`).toPromise()
    else
      return new Promise<void>(resolve => resolve());
  }

}
