import { APP_CONFIG, IAppConfig } from 'src/app/core/services/IAppConfig';
import { Component, ElementRef, Inject, ViewChild, OnChanges, SimpleChanges, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';


import { HttpEventType } from '@angular/common/http';
import { PublicShareService } from 'src/app/shared/service/public-share-service';
import { FileResultDto } from '../Dto/file-upload-dto';
import { PublicService } from 'src/app/core/services/publicService';

@Component({
  selector: 'mn-file-upload',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: UploadFileComponent, multi: true },

  ],
  template: `   
  
    <input type="file"  #fileInput [multiple]="multiple"  style="display: none"  (change)="fileChangeEvent($event)"  >
    {{placeholder | translate}}
  
    <button style="width:200px;" type="button"  (click)="fileInput.click()" class="btn btn-outline-primary btn-block mg-b-10"><mat-icon class="mg-r-10"> cloud_upload</mat-icon> Select files...</button>
    
 
 

 <div style="max-height: 250px;">
        <div class="row" style="border-bottom:1px solid #ddd;" *ngFor="let item of resultList  ;let i=index">
        <!-- <div class="col-md-6" > {{item.FileName}} </div>-->
            <div class="col-md-10"> <file-download [lable]="item.FileName" [FileId]="item.FileId" [formType]="'Order'"> </file-download> </div>
            <div class="col-md-2"> 
            <a title="Delete file" style="color:red;cursor:pointer" (click)="onDelete(item,i)">
                <mat-icon>delete</mat-icon>
            </a></div>

            
        </div>
    </div>
 `,
  animations: [
    trigger('selectedImage', [
      state('hideImage', style({
        opacity: 0,
      })),
      state('showImage', style({
        opacity: 1
      })),
      transition('showImage=>hideImage', [
        animate(0)
      ]),
      transition('hideImage=>showImage', [
        animate(650)
      ])
    ])
  ]
})
export class UploadFileComponent implements OnChanges, ControlValueAccessor {

  constructor(private publicShareService: PublicShareService,
    private publicService: PublicService) {


  }

  @Input("placeholder") placeholder: string = '';
  @Input("formType") formType: string = '';
  @Input("multiple") multiple: boolean;
  @Output() fileupInputChange = new EventEmitter<any>();
  //@Output() imageChange = new EventEmitter<any>();
  @Input("fileupInput") fileupInput: any;
  //  formControl: import("@angular/forms").AbstractControl;
  fileToUpload: File;
  progress: number = 0;
  fileId: any;
  orgFileName: string;
  filename: any;
  resultList: FileResultDto[] = [];
  imageState = "NewFile";
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileText') fileText: ElementRef;

  fileChangeEvent(input: any) {
    if (input.target.files && input.target.files.length > 0) {
      this.onUpload(input);
      // this.imageState = "IsDeleteFile"

    }
  }



  onDelete(item: FileResultDto, index) {

    if (this.multiple) {
      this.publicShareService.onDelete("FileUploadPanel", item.FileId).subscribe((res: any) => {
        this.publicService.onSuccessMessege(res);
        this.resultList.splice(index, 1);

        if (this.resultList.length == 0) {
          this.imageState = "NewFile";
          this.fileInput.nativeElement.value = null;
          this.fileupInputChange.emit(null);
        }

        if (this.resultList.length != 0) {
          if (this.multiple)
            this.fileupInputChange.emit(this.resultList.map(c => c.FileId));
          else
            this.fileupInputChange.emit(this.resultList.map(c => c.FileId)[0]);

        }

        this.progress = 0;
        this.writeValue(null);

      })

    } else {
      this.resultList.splice(index, 1);
      this.imageState = "NewFile";
      this.fileInput.nativeElement.value = null;
      this.fileupInputChange.emit(null);
      this.progress = 0;
      this.writeValue(null);
    }



  }


  onUpload(input: any) {
    this.imageState = "";
    const formData = new FormData();
    if (this.multiple)

      for (var i = 0; i < input.target.files.length; i++)
        formData.append("file[" + i + "]", input.target.files[i]);
    else
      formData.append('File', <File>input.target.files[0], input.target.files[0].name);

    this.publicShareService.uploadFile("FileUploadPanel?formType=" + this.formType, formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);

      }
      else {

        if (event.status == 201 && event.body !== null && event.body !== undefined) {

          this.resultList.push(...event.body);
          if (this.resultList.length != 0) {
            if (this.multiple)
              this.fileupInputChange.emit(this.resultList.map(c => c.FileId));
            else {
              this.fileupInputChange.emit(this.resultList.map(c => c.FileId)[0]);
              this.imageState = "IsDeleteFile"

            }
          }

        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.fileupInput.currentValue != undefined && changes.fileupInput.currentValue != "" && changes.fileupInput.currentValue != null) {
      setTimeout(() => {
        let fileId = "";
        if (this.multiple == true) {
          if (this.fileupInput.length > 0)
            fileId = this.fileupInput[0];
        } else
        {  fileId = this.fileupInput;
          this.imageState = "IsDeleteFile"
        }
         this.publicShareService.getAll("FileUploadPanel?Id=" + fileId + "&FormType=" + this.formType).subscribe(res => {
          this.resultList.push(...res);
          this.resultList = this.resultList.filter(
            (thing, i, arr) => arr.findIndex(t => t.FileId === thing.FileId) === i
          );
   
        });
      });

    }
  }

  onChange = (_: any) => {

    if (_.target.files && _.target.files.length > 0) {
      this.filename = _.target.files[0].name;
      this.imageState = "IsDeleteFile"

    }
  };
  onTouched = (_: any) => { };

  writeValue(value) {

  }





  registerOnChange(fn: any) { this.onChange = fn; }
  registerOnTouched(fn: any) { this.onTouched = fn; }

}
