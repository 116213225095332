import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridColumn, GridRequest, GridResult } from 'src/app/base-class/grid-classes';

import { AuthService } from 'src/app/core/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { NgxPermissionsService } from 'ngx-permissions';
import { PublicService } from 'src/app/core/services/publicService';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserRoleDto } from 'src/app/base-class/dto/UserRoleDto';
import { BrowserStorageService } from 'src/app/core/services/browser-storage.service';

@Component({
  selector: 'app-selected-user-role-component',
  templateUrl: './selected-user-role-component.component.html',
  styleUrls: ['./selected-user-role-component.component.scss']
})

export class SelectedUserRoleComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line: max-line-length
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, 
    private browserStorageService :BrowserStorageService,
    private publicService: PublicService, private permissionsService: NgxPermissionsService) { }
  error: string;
  // tslint:disable-next-line: member-ordering
  itemsPerPage = this.publicService.itemsPerPage;
  userRoleSubscription: Subscription;
  currentPage: number;
  numberOfPages: number;
  query: GridRequest = { PageSize: 10, Page: 1, Sort: [], Filters: null };
  queryResult = new GridResult<UserRoleDto>(0, []);
  isLoading = false;
  columns: GridColumn[] = [
    new GridColumn('Unit Name', 'UnitName', true, 'string', 300, 'contains', false),
    new GridColumn('Role', 'RoleTitle', true, 'string', 300, 'contains', false)
  ];
  isShowGrid: boolean= false
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //systemsId: string = ''
  ngOnInit() {

    //this.systemsId = this.route.snapshot.params['systemId']

    this.currentPage = this.paginator?this.paginator.pageIndex:1;
    this.onPopulateGrid();

  }


  onPageChange(event: any) {
    //this.query.Page = event.page;
    this.itemsPerPage = this.query.PageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.query.Page = event.pageIndex + 1;
    this.onPopulateGrid();
  }


  onPopulateGrid() {
    this.userRoleSubscription = this.authService.getUserRole('UserRoleSelectPanel/getPage', this.query, null).subscribe(res => {
      this.queryResult = res;
      if (this.queryResult.Total == 1)
        this.onSelectUserRole(this.queryResult.Data[0])

      else
        this.isShowGrid = true;
    });

  }
  onGridSort(columnName: string) {

    this.query = this.publicService.onGridSort(this.query, columnName);
    this.onPopulateGrid();

  }
  onGridFilter(filterObj: any) {

    let res = this.publicService.onGridFilter(this.query, this.columns, filterObj.filterColumn, filterObj.input, filterObj.Operator);
    this.query = res.gridRequest;
    if (res.isRefreshGird)
      this.onPopulateGrid();
  }

  onSelectUserRole(userRole: UserRoleDto) {



    this.authService.getRefreshToken(userRole.UserRoleId).subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        this.router.navigate(['login']);
      } else {
        this.onGetPermissions(userRole);



      }

    },
      (error: HttpErrorResponse) => {
        console.error('Login error', error);
        if (error.status === 401) {
          this.error = 'Please Login Again';
        } else {
          this.error = `${error.statusText}: ${error.message}`;
        }
      });


  }
  ngOnDestroy(): void {
    this.userRoleSubscription.unsubscribe();
  }

  onGetPermissions(userRole: UserRoleDto) {
    this.authService.getUserPermission('Permissions/PermissionWithRole').subscribe((res: string[]) => {
      this.permissionsService.loadPermissions(res);
      sessionStorage.setItem('IsLog', 'true');

      if (userRole.RedirectUrl) {
        window.location.href = userRole.RedirectUrl  +"?t=" +  encodeURIComponent(this.browserStorageService.getLocal("access_token"))
        return
      }


      this.router.navigate(['panel/dashboard']);
    });
  }
}
