import { Injectable } from '@angular/core';

@Injectable()
export class userInfo {
  public static role: string = '';
  public static fullName: string = '';
  public static id: string = '';
  public static userImg: string = '';
  public static unitName: string = '';
}


export interface ResultTokenDto {
  Token: string
  IsMultiRole: boolean
  Message: string
  RedirectUrl: string
  SystemName: string
  IsSuccess: boolean
}

export interface TokenInfo {
  IP_Address: string,
  UserId: string,
  UserName: string,
  RoleId: string,
  RoleCode: string,
  UserRoleId: string,
  UnitId: string,
  LanguageCode: string,
  exp: number,
  iss: string,
  aud: string
}
